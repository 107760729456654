import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { ProviderModalButtonProps } from '../types';

export const ProviderModalButton = ({
  organization,
  ModalComponent,
  buttonText,
  onFinish,
  startIcon,
  color,
}: ProviderModalButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAddProvidersClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Button
        style={{ marginLeft: '10px' }}
        variant="contained"
        startIcon={startIcon}
        component="span"
        onClick={handleAddProvidersClick}
        color={color}
      >
        {buttonText}
      </Button>

      <ModalComponent
        isOpen={isModalOpen}
        onClose={handleModalClose}
        organization={organization}
        onFinish={onFinish}
      />
    </>
  );
};
