import MaterialTable, { Column, Options } from 'material-table';
import { SaveAlt } from '@material-ui/icons';
import { utils, writeFile, WorkBook } from 'xlsx';
import React, { useEffect } from 'react';
import { useApi } from '../../api/use-api';
import { tableIcons } from '../../assets/material-table-icons';
import { useSafeState } from '../../common/use-safe-state';
import { OrganizationAdmin } from '../../common/user/types';
import { getOrganizationAdminsQuery } from '../../api/organization-admins';
import { getAllOrganizationsQuery } from '../../api/organization';
import { GetAllOrganizationsResult, Organization } from '../../generated/graphql';

export const OrganizationAdmins = () => {
  const [organizationAdmins, setOrganizationAdmins] = useSafeState<OrganizationAdmin[]>([]);
  const [allOrganizations, setAllOrganizations] = useSafeState<Organization[]>([]);
  const [getOrganizations] = useApi<void, GetAllOrganizationsResult>(getAllOrganizationsQuery);
  const [getOrganizationAdmins, getOrganizationAdminsLoading] = useApi<void, OrganizationAdmin[]>(
    getOrganizationAdminsQuery,
  );

  useEffect(() => {
    const run = async () => {
      const [allOrganizationsResult, organizationAdminsResult] = await Promise.all([
        getOrganizations(),
        getOrganizationAdmins(),
      ]);

      const organizationAdminstData = organizationAdminsResult?.data?.getOrganizationAdmins ?? [];
      const allOrganizationsData = allOrganizationsResult?.data?.getAllOrganizations
        ?.results as Organization[];

      setOrganizationAdmins(organizationAdminstData);
      setAllOrganizations(allOrganizationsData);
    };

    run();
  }, []);

  const getColumns = (): Column<OrganizationAdmin>[] => {
    const columns = [
      { title: 'ID', field: 'id' },
      { title: 'Email', field: 'email' },
      { title: 'Organization ID', field: 'organizationId' },
      { title: 'Organization Name', field: 'organizationName' },
    ];

    return columns;
  };

  const getTableTitle = (): string => `Organization Admins (${organizationAdmins?.length})`;

  const tableOptions: Options<OrganizationAdmin> = {
    pageSize: 10,
    headerStyle: { backgroundColor: '#18202c', color: '#FFF' },
  };

  const addTableToExcel = (workbook: WorkBook) => {
    const noTableData = (organizationAdmins as (OrganizationAdmin & {
      tableData: number;
    })[]).map(({ tableData, ...row }) => Object.values(row));

    const worksheet = utils.json_to_sheet(noTableData);

    utils.book_append_sheet(workbook, worksheet, 'Organization Admins');
    utils.sheet_add_aoa(worksheet, [getColumns().map((col) => col.title)], { origin: 'A1' });
  };

  const addOrganizationWithoutAdminsToExcel = (workbook: WorkBook) => {
    const allOrgIdsWithAdmins = new Set(organizationAdmins.map((admin) => +admin.organizationId));
    const allOrganizationsWithoutAdmins = allOrganizations
      .filter((org) => !allOrgIdsWithAdmins.has(org.id!))
      .map((org) => ({ id: org.id!, name: org.name }))
      .sort((a, b) => a.id - b.id);
    const worksheet = utils.json_to_sheet(allOrganizationsWithoutAdmins);

    utils.book_append_sheet(workbook, worksheet, 'Organizations Without Admins');
    utils.sheet_add_aoa(worksheet, [['Organizatio ID', 'Organization Name']], { origin: 'A1' });
  };

  const handleExcelDownload = async () => {
    const workbook = utils.book_new();
    addTableToExcel(workbook);
    addOrganizationWithoutAdminsToExcel(workbook);
    writeFile(workbook, 'Organization Admins.xlsx');
  };

  return (
    <MaterialTable
      title={getTableTitle()}
      icons={tableIcons}
      actions={[
        {
          icon: () => <SaveAlt />,
          tooltip: 'Export As Excel',
          isFreeAction: true,
          onClick: () => {
            handleExcelDownload();
          },
        },
      ]}
      columns={getColumns()}
      data={organizationAdmins}
      options={tableOptions}
      isLoading={getOrganizationAdminsLoading}
    />
  );
};
