import { isEqual } from 'lodash-es';
import { OrganizationProduct } from '../../generated/graphql';

export function buildOrgProductsByOrgConfig(
  currentOrganizationProducts: OrganizationProduct[],
  organizationChanges: any,
): OrganizationProduct[] {
  const newProducts = currentOrganizationProducts.map((organizationProduct) => {
    const newProduct = organizationChanges.applications[organizationProduct.name];

    if (newProduct) {
      return {
        ...organizationProduct,
        contentSources: newProduct.contentSources,
        sources: undefined,
        providerSources: undefined,
      };
    }

    return {
      ...organizationProduct,
    };
  });

  return isEqual(currentOrganizationProducts, newProducts)
    ? currentOrganizationProducts
    : newProducts;
}

export function buildOrgConfigByOrgProducts(
  currentOrganizationConfig: any,
  organizationProducts: OrganizationProduct[],
): any {
  const newConfig = { ...currentOrganizationConfig, applications: {} };
  organizationProducts.forEach((organizationProduct) => {
    newConfig.applications[organizationProduct.name] = {
      ...(currentOrganizationConfig?.applications?.[organizationProduct.name] || {}),
      sources: organizationProduct.sources,
      source_direct: organizationProduct.providerSources?.[0],
      contentSources: organizationProduct.contentSources,
    };
  });

  return newConfig;
}
