import AddIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import GroupIcon from '@material-ui/icons/Group';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { startCase } from 'lodash-es';
import MaterialTable, { Column } from 'material-table';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteOrganizationMutation, getAllOrganizationsQuery } from '../../api/organization';
import { useApi } from '../../api/use-api';
import { tableIcons } from '../../assets/material-table-icons';
import { productDisplaysNames } from '../../common/app-product-map';
import { prettyEhrName } from '../../common/ehr';
import { useSafeState } from '../../common/use-safe-state';
import { GetAllOrganizationsResult, Organization } from '../../generated/graphql';
import { store } from '../../stores/store';
import { messages } from './messages';
import NotSupported from '../common/NotSupported';
import { Team, useFeatureFlag } from '@getvim/feature-flags-react';

interface IdInputWrapper {
  id: number;
}

export const Organizations = () => {
  const history = useHistory();

  const [organizations, setOrganizations] = useSafeState<Organization[]>([]);
  const [getAllOrgs, getAllOrgsLoading] = useApi<never, GetAllOrganizationsResult>(
    getAllOrganizationsQuery,
  );
  const [shouldBlockOrganizaionCreationAndEdit] = useFeatureFlag({
    flagName: 'console.shouldBlockOrganizaionCreationAndEdit',
    defaultValue: false,
    team: Team.OMT,
  });
  const [deleteOrg] = useApi<IdInputWrapper, boolean>(deleteOrganizationMutation);

  useEffect(() => {
    const run = async () => {
      const orgs = await getAllOrgs();
      const organizationsData = (orgs?.data?.getAllOrganizations?.results ?? []) as Organization[];
      setOrganizations(organizationsData);
    };
    run();
  }, []);

  const organizationColumns = [
    { title: 'ID', field: 'id', defaultSort: 'asc' },
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'URL',
      field: 'ehrUrl',
      hidden: true,
      searchable: true,
    },
    {
      title: 'EHR System',
      field: 'ehrSystem',
      render: (org: Organization) => {
        const ehrSystemName = prettyEhrName(org.ehrSystem);
        return org.ehrUrl ? <a href={org.ehrUrl}>{ehrSystemName}</a> : ehrSystemName;
      },
    },
    {
      title: 'Products',
      field: 'products',
      render: (org: Organization) => (
        <div>
          {(org.products ?? [])
            .map(({ name }) => startCase(productDisplaysNames[name]) || startCase(name))
            .join(', ')}
        </div>
      ),
    },
    {
      title: 'Organization Key',
      field: 'organizationKey',
      hidden: true,
      searchable: true,
    },
    {
      title: 'Is LTS',
      field: 'isLts',
      width: '60%',
      render: (org: Organization) => (org.isLts ? <CheckIcon /> : <CloseIcon />),
    },
  ] as Column<any>[];

  const [shouldOpenNotSupportedDialog, setShouldOpenNotSupportedDialog] = useState(false);
  function openNotSupportedDialog(): void {
    setShouldOpenNotSupportedDialog(true);
  }

  function handleClose(): void {
    setShouldOpenNotSupportedDialog(false);
  }

  return (
    <>
      {shouldBlockOrganizaionCreationAndEdit && (
        <NotSupported open={shouldOpenNotSupportedDialog} handleClose={handleClose} />
      )}
      <div>
        <MaterialTable
          title={`Organizations (${organizations?.length})`}
          icons={tableIcons}
          columns={organizationColumns}
          data={organizations}
          options={{
            pageSize: 10,
            headerStyle: {
              backgroundColor: '#18202c',
              color: '#FFF',
            },
          }}
          isLoading={getAllOrgsLoading}
          actions={[
            (organization) => ({
              icon: () => <GroupIcon />,
              tooltip: 'Users',
              onClick: () => history.push(`users?organization_id=${organization.id}`),
            }),
            {
              icon: () => <EditIcon />,
              onClick: (_, org) =>
                shouldBlockOrganizaionCreationAndEdit
                  ? openNotSupportedDialog()
                  : history.push(`organization/${org.id}`),
            },
            {
              isFreeAction: true,
              icon: () => <AddIcon />,
              onClick: () =>
                shouldBlockOrganizaionCreationAndEdit
                  ? openNotSupportedDialog()
                  : history.push('organization/new'),
            },
          ]}
          editable={{
            onRowDelete: (oldData) => {
              if (!oldData || !oldData.id) {
                return Promise.resolve();
              }
              return deleteOrg({ id: oldData.id })
                .then((result) => {
                  if (!result) {
                    store.showSnackbar(messages.deleteError);
                    return;
                  }
                  const data = [...organizations];
                  data.splice(data.indexOf(oldData), 1);
                  setOrganizations(data);
                  store.showSnackbar(messages.deleteSuccess);
                })
                .catch(() => {
                  store.showSnackbar(messages.deleteError);
                });
            },
          }}
        />
      </div>
    </>
  );
};
