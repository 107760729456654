import * as yup from 'yup';

const rowSchema = yup.object().shape({
  npi: yup.string().required(),
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
});

const locationValidationMessage = (field) =>
  `"${field.path}" field is empty. Either fill all locations fields or leave them empty.`;

const rowSchemaWithLocation = yup.object().shape({
  npi: yup.string().required(),
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  addressLine1: yup.string().required(locationValidationMessage),
  city: yup.string().required(locationValidationMessage),
  state: yup.string().required(locationValidationMessage),
  zip: yup.string().required(locationValidationMessage),
});

const validateRow = (
  row: Record<string, string | null>,
): row is yup.InferType<typeof rowSchema> => {
  if (
    Object.keys(row).some(
      (value) => ['addressLine1', 'city', 'state', 'zip'].includes(value) && !!row[value],
    )
  ) {
    rowSchemaWithLocation.validateSync(row);
  } else {
    rowSchema.validateSync(row);
  }
  return true;
};

export const validateData = (data: Record<string, string | null>[]) => {
  const errors: Array<{ index: number; messages: string[] }> = [];
  const npiSet = new Set();
  const validSet = new Set();
  data.forEach((row, index) => {
    try {
      if (validateRow(row)) {
        const nameHash = `${row.npi}${row.firstName}${row.lastName}`.toLowerCase();
        if (npiSet.has(row.npi) && !validSet.has(nameHash)) {
          errors.push({
            index: index + 1,
            messages: ['Duplicate NPI with different first + last name'],
          });
        } else {
          npiSet.add(row.npi);
          validSet.add(nameHash);
        }
      }
    } catch (error) {
      errors.push({ index: index + 1, messages: (error as yup.ValidationError).errors });
    }
  });
  return errors;
};
