export enum ProductName {
  OptumReferralUtility = 'optum-referral-utility',
  RxAssist = 'rx-assist',
}

type ProductDisplayNameMap = {
  [key in ProductName]: string;
};

export const productDisplaysNames: Partial<ProductDisplayNameMap> = {
  [ProductName.OptumReferralUtility]: 'optimal-care',
  [ProductName.RxAssist]: 'rx-assist',
};
