import { useState, useRef, useEffect } from 'react';

export function useSafeState<T>(initialState?: T | (() => T)): [T, (newState: T) => void] {
  const [state, setSate] = useState<T>(initialState as any);
  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  const safeSetState = (newState) => {
    if (isMounted.current) {
      setSate(newState);
    }
  };

  return [state, safeSetState];
}
