/* eslint-disable react-hooks/exhaustive-deps */
import { transformEhrUrl } from '@getvim/ehr-url-manipulation';
import { isEmpty } from 'lodash-es';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { isEhrSupportSeamlessQuery, isSeamlessAvailableQuery } from '../../../api/seamless';
import { useApi } from '../../../api/use-api';
import {
  IsEhrSupportSeamlessArgs,
  IsSeamlessAvailableArgs,
  IsSeamlessAvailableResult,
  Organization,
} from '../../../generated/graphql';
import { getSeamlessIcon, getStatus, SeamlessIndicationStatus } from './utils';
import { ehrSystemsConfig } from '../utils';

interface SeamlessIndicatorIconProps {
  setOrganization: Dispatch<SetStateAction<Organization>>;
  organization: Organization;
  shouldCheckSeamless: boolean;
  onStatusChange: (status: SeamlessIndicationStatus) => void;
  shouldRemoveStrictFromURLTransformersFF?: boolean;
}

export const SeamlessIndicatorIcon = ({
  setOrganization,
  organization,
  shouldCheckSeamless,
  onStatusChange,
  shouldRemoveStrictFromURLTransformersFF,
}: SeamlessIndicatorIconProps) => {
  const [ehrSupportSeamless, setEhrSupportSeamless] = useState(false);
  const [seamlessAvailable, setSeamlessAvailable] = useState(false);
  const [firstRequest, setFirstRequest] = useState(false);
  const [url, setUrl] = useState(organization.ehrUrl ?? '');

  // API
  const [isEhrSupportSeamless] = useApi<IsEhrSupportSeamlessArgs, boolean>(
    isEhrSupportSeamlessQuery,
  );

  const [isSeamlessAvailable, isSeamlessAvailableLoading] = useApi<
    IsSeamlessAvailableArgs,
    IsSeamlessAvailableResult
  >(isSeamlessAvailableQuery);

  useEffect(() => {
    checkIfEhrSupportSeamless(organization.ehrSystem ?? '');
  }, [organization.ehrSystem]);

  // verify if need to send request of seamless available on loading of component
  useEffect(() => {
    if (isEmpty(url) && !isEmpty(organization.ehrUrl) && url !== organization.ehrUrl) {
      setFirstRequest(true);
    }
    setUrl(organization.ehrUrl ?? '');
  }, [organization.ehrUrl]);

  useEffect(() => {
    const checkIfSeamlessAvailable = async () => {
      if (
        ehrSupportSeamless &&
        organization.ehrSystem &&
        organization.ehrUrl &&
        (shouldCheckSeamless || firstRequest)
      ) {
        try {
          const { data } = await isSeamlessAvailable({
            input: {
              ehr: organization.ehrSystem,
              url: transformEhrUrl(organization.ehrUrl, organization.ehrSystem, {
                strict: shouldRemoveStrictFromURLTransformersFF ? false : true,
              }),
            },
          });

          setOrganization((currentOrganization) => ({
            ...currentOrganization,
          }));

          setSeamlessAvailable(data?.isSeamlessAvailable.isReachable);
        } catch {
          setSeamlessAvailable(false);
        }

        setFirstRequest(false);
      } else if (isEmpty(organization.ehrUrl)) {
        if (organization.ehrSystem) {
          const { disabledEhrUrl } = ehrSystemsConfig[organization.ehrSystem];
          setSeamlessAvailable(disabledEhrUrl);
        } else {
          setSeamlessAvailable(false);
        }
      }
    };
    checkIfSeamlessAvailable();
  }, [
    ehrSupportSeamless,
    organization.ehrSystem,
    organization.ehrUrl,
    shouldCheckSeamless,
    firstRequest,
  ]);

  const checkIfEhrSupportSeamless = async (ehrSystem: string) => {
    if (ehrSystem) {
      const result = await isEhrSupportSeamless({ input: ehrSystem });
      const value = result.data?.isEhrSupportSeamless;
      setEhrSupportSeamless(value);
      if (value) {
        // first request of current system
        setFirstRequest(true);
      }
    } else {
      setEhrSupportSeamless(false);
    }
  };

  const status = getStatus(
    ehrSupportSeamless,
    seamlessAvailable,
    isSeamlessAvailableLoading,
    organization.identityProvider,
  );
  onStatusChange(status);
  const icon = getSeamlessIcon(status);
  return ehrSupportSeamless ? icon : null;
};
