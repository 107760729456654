import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { tableIcons } from '../../../assets/material-table-icons';
import {
  ProductAction,
  ProductItem,
  SelectedUsers,
  UpdateOrganizationUsersModalProps,
} from '../types';
import { UpdateOrgUsersForm } from './update-org-users-form';
import { MultipleLabelsTextField } from '../../common/multi-label-txt-field';

export const AddChangesToUsersModal = ({
  isOpen,
  onClose,
  products,
  setProductsToAdd,
  setProductsToRemove,
  setTinUpdate,
}: UpdateOrganizationUsersModalProps) => {
  const [productList, setProductList] = useState<ProductItem[]>([]);
  const [isTinEmpty, setIsTinEmpty] = useState<boolean>(true);
  const [tin, setTin] = useState<string[]>([]);
  const [tinSelectedUsers, setTinSelectedUsers] = useState<SelectedUsers>(SelectedUsers.None);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetProductList(), [products]);

  const resetProductList = () => {
    setProductList(
      products?.map((product) => {
        return { name: product.name, action: ProductAction.Unchanged };
      }) || [],
    );
  };

  const toggleProductAction = (productName: string, action: ProductAction) => {
    const newProductsList = productList.map((product) => {
      if (product.name === productName) {
        if (product.action === action) {
          return { ...product, action: ProductAction.Unchanged };
        }

        return { ...product, action };
      }
      return product;
    });
    setProductList(newProductsList);
  };

  const submitAndClose = () => {
    setProductsToAdd(
      productList
        .filter((product) => product.action === ProductAction.Add)
        .map((product) => product.name),
    );
    setProductsToRemove(
      productList
        .filter((product) => product.action === ProductAction.Remove)
        .map((product) => product.name),
    );
    setTinUpdate({ tin, selectedUsers: tinSelectedUsers });
    onClose();
  };

  const cancelAndClose = () => {
    resetProductList();
    setProductsToAdd([]);
    setProductsToRemove([]);
    onClose();
  };

  return (
    <Dialog open={isOpen}>
      <Box display="flex" justifyContent="space-between" paddingRight="1rem">
        <DialogTitle>Add changes to all organization users</DialogTitle>
        <IconButton onClick={cancelAndClose}>
          <tableIcons.Close />
        </IconButton>
      </Box>
      <Box style={{ marginTop: '7px', marginLeft: '20px' }}>
        <p>
          You can choose one or more bulk changes. The desired actions will be applied when the
          organization form is submitted. Please select carefully.
        </p>
      </Box>
      <DialogContent dividers>
        <Box>
          <h4>Applications</h4>
          <TableContainer>
            <Table className="bulkEditModal-Table">
              <TableHead>
                <TableRow>
                  <TableCell className="column1">Application Name</TableCell>
                  <TableCell className="column2">Add</TableCell>
                  <TableCell className="column3">Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productList.map((product) => (
                  <TableRow key={product.name}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={product.action === ProductAction.Add}
                        onChange={() => toggleProductAction(product.name, ProductAction.Add)}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={product.action === ProductAction.Remove}
                        onChange={() => toggleProductAction(product.name, ProductAction.Remove)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <UpdateOrgUsersForm
          title="TIN"
          name="TIN"
          disabled={isTinEmpty}
          onChange={({ selectedUsers }) => {
            setTinSelectedUsers(selectedUsers);
          }}
        >
          <MultipleLabelsTextField
            inputLabel="Tax ID Number"
            onChange={(labels) => {
              setIsTinEmpty(!labels.length);
              setTin(labels);
            }}
          />
        </UpdateOrgUsersForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelAndClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={submitAndClose} autoFocus>
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
};
