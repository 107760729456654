import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  BrandedH1,
  Button,
  InputStyleWrapper,
  Text,
  Container,
  ColorNameEnum,
  SizeEnum,
  WeightEnum,
  InputStyle,
} from '@getvim/atomic-ui';
import vimLogoSm from '@getvim/atomic-ui/assets/images/logos/vimLogoSm.png';
import { usePasswordReset } from './password-reset/usePasswordReset';
import { useActivateUser } from './password-reset/activateUser';
import { UserType } from '../generated/graphql';
import { removeHeadingHashTag, removeTrailingHashTag } from '../common/url';
import thankYouLogo from './ThankYouLogo.png';
import linkExpiredImg from './linkExpiredImg.png';

import './ehr-login.less';
import './resetPasswordRedirect.less';
import { parseJwt } from '../common/jwt';
import {
  env,
  getConsoleUrlByEnv,
  getEnvByUrlHost,
  getFullOrganizationConsoleUrl,
} from '../common/env';
import { useAnalytics } from '../components/providers/analytics-provider';
import { ANALYTICS_EVENTS } from './analytics';
import { useLocation } from 'react-router-dom';
import { Team } from '@getvim/feature-flags';
import { useFeatureFlag } from '@getvim/feature-flags-react';

const ACTIVE_USER_TEXT = `This link has been used before and your password has already been set.\n\nTo access the management console and enter your username and password, click on this `;
const NON_ACTIVE_USER_TEXT =
  'Our links expire after 24 hours to keep your account secure.\n\nClick below to get a new link.';
const ENTER_EMAIL_TEXT = 'Enter your email address,\n and we will send you a new invite';
const AUTH0_ACCESS_EXPIRED_ERROR = 'Access expired.';

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
let analyticsRetryCounter = 0;
const ANALYTICS_RETRY_INTERVAL = 1000;
const ANALYTICS_MAX_RETRIES = 5;

export function ResetPasswordRedirect() {
  const analyticsClient = useAnalytics();

  const [inviteSent, setInviteSent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isFormValid, setFormValidity] = useState(false);
  const [email, setEmail] = useState('');
  const [validatedEnv, setValidatedEnv] = useState(false);
  const [shouldUseUniversalLogin] = useFeatureFlag({
    flagName: 'console.shouldUseUniversalLogin',
    defaultValue: false,
    team: Team.OMT,
  });

  const { hash } = useLocation();
  const [, queryString] = removeTrailingHashTag(window.location.href).split('?');

  const urlSearchParams = new URLSearchParams(queryString);
  const success = urlSearchParams.get('success') === 'true';
  const auth0Error = urlSearchParams.get('error_description');
  const token = urlSearchParams.get('token') || removeHeadingHashTag(hash);
  const [shouldUseRestApiFF, isLoadingShouldUseRestApiFF] = useFeatureFlag({
    flagName: 'organization-console.shouldUseRestForPublicEndpoints',
    defaultValue: false,
    team: Team.OMT,
  });
  const { resetPassword } = usePasswordReset();
  const { activateUser } = useActivateUser();

  const {
    userEmail,
    shouldRedirectToConsole,
    isOrgAdmin,
    isUserActivated,
    shouldDisableEmailChange,
    accountMetadata,
    auth0UserId,
    sourceEnv,
    sourceEndpoint,
  } = useMemo(() => {
    const parsedToken = parseJwt(token);
    if (!parsedToken) {
      return {};
    }
    let shouldDisableEmailChange = false;
    if (parsedToken.userEmail && parsedToken.shouldRedirectToConsole) {
      shouldDisableEmailChange = true;
    }
    return { ...parsedToken, shouldDisableEmailChange };
  }, [token]);
  const resendInvite = async () => {
    setInviteSent(false);
    setLoading(true);
    const emailToSendTo = shouldDisableEmailChange ? userEmail : email || userEmail;
    await resetPassword(
      emailToSendTo,
      [UserType.VimAdmin, UserType.VimEhrUser],
      shouldUseRestApiFF,
    );
    sendAnalytic(ANALYTICS_EVENTS.REQUESTED_NEW_RESET_PASSWORD_LINK);
    setLoading(false);
    setInviteSent(true);
  };

  const sendAnalytic = useCallback(
    async (event: string, extraProperties?: any) => {
      // Requires retry because analytics have async calls in constructor
      analyticsRetryCounter++;
      if (analyticsRetryCounter >= ANALYTICS_MAX_RETRIES) {
        analyticsRetryCounter = 0;
        return;
      }
      if (!analyticsClient.getBaseProperties()) {
        analyticsClient.setBaseProperties({ vim_user: { user_id: auth0UserId } });
      }

      if (analyticsClient && auth0UserId && userEmail && accountMetadata) {
        try {
          await analyticsClient.track({
            event,
            properties: {
              email: userEmail,
              account_id: accountMetadata.id,
              account_name: accountMetadata.name,
              ...extraProperties,
            },
          });
          analyticsRetryCounter = 0;
        } catch {
          await sleep(ANALYTICS_RETRY_INTERVAL);
          sendAnalytic(event, extraProperties);
        }
      }
    },
    [accountMetadata, userEmail, analyticsClient, auth0UserId],
  );

  useEffect(() => {
    // For lower env auth0 tenant, if you reached staging but your mail env is local, redirect to local
    if (!token) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const loginRedirectURL = urlParams.get('login_redirect_url');
      const source = urlParams.get('source');
      if (!loginRedirectURL) {
        return;
      }
      const redirectURL = new URL(decodeURIComponent(loginRedirectURL));
      if (source) {
        redirectURL.searchParams.set('source', source);
      }
      window.location.href = redirectURL.href;
    } else {
      const urlEnv = getEnvByUrlHost();
      if (sourceEnv && urlEnv !== sourceEnv) {
        const currentUrl = new URL(window.location.href);
        currentUrl.port = '';
        currentUrl.host = getConsoleUrlByEnv(sourceEnv);
        if (sourceEnv === 'local') {
          currentUrl.protocol = 'http:';
        }
        window.location.href = currentUrl.href;
      }
      setValidatedEnv(true);
    }
  }, [sourceEnv, token]);

  useEffect(() => {
    const setActivateUser = async () => {
      await activateUser(token, shouldUseRestApiFF);
      sendAnalytic(ANALYTICS_EVENTS.CHANGED_PASSWORD_SUCCESSFULLY);

      const queryParams = sourceEndpoint ? `?source=${sourceEndpoint}` : '';
      if (shouldRedirectToConsole || isOrgAdmin) {
        window.location.href =
          env === 'local'
            ? `http://localhost:3014/organization-admin/${queryParams}`
            : `${window.location.origin}/organization-admin${queryParams}`;
      }
    };

    if (success && token && validatedEnv && !isLoadingShouldUseRestApiFF) setActivateUser();
  }, [
    activateUser,
    success,
    token,
    validatedEnv,
    isOrgAdmin,
    sendAnalytic,
    shouldRedirectToConsole,
    sourceEndpoint,
    shouldUseRestApiFF,
    isLoadingShouldUseRestApiFF,
  ]);

  useEffect(() => {
    if (!success) {
      const extraParams: { used?: boolean } = {};
      if (auth0Error === AUTH0_ACCESS_EXPIRED_ERROR) {
        extraParams.used = true;
      }
      sendAnalytic(ANALYTICS_EVENTS.EXPIRED_PAGE_LOADED, extraParams);
    }
  }, [success, sendAnalytic, auth0Error]);

  if (success) {
    return (
      <div className="success-page">
        <div className="main-content">
          <Container>
            <div className="success-img text-center margin-top-50">
              <img className="success-img" src={thankYouLogo} alt="" />
            </div>

            <BrandedH1 className="success-main-title" text="success!" />
            <BrandedH1 className="text-normal" margin="none" text="Your password is set." />
          </Container>
        </div>
      </div>
    );
  }
  return (
    <div className="link-expired-page">
      <div className="main-content">
        <Container>
          <div className="top-logo text-center margin-top-50">
            <img src={vimLogoSm} alt="" />
          </div>
          <div className="top-img text-center">
            <img src={linkExpiredImg} alt="linked-expired" />
          </div>
          <Text
            size={SizeEnum['22px']}
            weight={WeightEnum.bold}
            colorName={ColorNameEnum.theme}
            className="margin-top"
          >
            Link expired
          </Text>
          <Text
            size={SizeEnum['16px']}
            colorName={ColorNameEnum.theme}
            className="margin-top-10"
            shouldBreakLines
          >
            {shouldDisableEmailChange
              ? isUserActivated
                ? ACTIVE_USER_TEXT
                : NON_ACTIVE_USER_TEXT
              : ENTER_EMAIL_TEXT}
            {shouldDisableEmailChange && isUserActivated && (
              <a
                href={getFullOrganizationConsoleUrl(shouldUseUniversalLogin)}
                style={{ textDecorationLine: 'underline' }}
              >
                link
              </a>
            )}
          </Text>
          <div>
            {!isUserActivated && (
              <form name="resend-invite">
                {!shouldDisableEmailChange && (
                  <InputStyleWrapper inputStyle={InputStyle.large} className="margin-top-30">
                    <div className="input">
                      <input
                        type="text"
                        placeholder="Enter your email"
                        onChange={({ target }) => {
                          setFormValidity(target.checkValidity());
                          setEmail(target.value);
                        }}
                        value={userEmail || email}
                        required
                      />
                    </div>
                  </InputStyleWrapper>
                )}
                <div className="text-center margin-top-30">
                  <Button
                    className="btn-blue"
                    onClick={resendInvite}
                    isLoading={isLoading}
                    disabled={!userEmail && !isFormValid}
                    style={{ fontSize: shouldDisableEmailChange ? '1.5rem' : '18px' }}
                  >
                    {shouldDisableEmailChange ? 'Send New Link to My Email' : 'Resend invite'}
                  </Button>
                </div>
              </form>
            )}
          </div>
          {inviteSent && (
            <Text className="margin-top-30">
              We’ve sent you a new invite. If you don’t receive it,
              <br /> please make sure you’ve entered your Email correctly
            </Text>
          )}
        </Container>
      </div>
    </div>
  );
}
