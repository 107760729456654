import MaterialTable, { Column, Options } from 'material-table';
import React, { useEffect } from 'react';
import { useApi } from '../../api/use-api';
import { createAdminMutation, getAdminsQuery } from '../../api/admins';
import { tableIcons } from '../../assets/material-table-icons';
import { emailRegex } from '../../common/regex';
import { useSafeState } from '../../common/use-safe-state';
import { transformUser } from '../../common/user/user-logic';
import { ExtendedUser } from '../../common/user/types';
import { deleteUserMutation } from '../../api/users';
import { store } from '../../stores/store';
import { messages } from './messages';
import { MutationCreateAdminArgs, MutationDeleteUserArgs, User } from '../../generated/graphql';

export const Admins = () => {
  const [admins, setAdmins] = useSafeState<ExtendedUser[]>([]);
  const [getAdmins, getAdminsLoading] = useApi<void, User[]>(getAdminsQuery);
  const [createAdminApi] = useApi<MutationCreateAdminArgs, User>(createAdminMutation);
  const [deleteAdmin] = useApi<MutationDeleteUserArgs, string>(deleteUserMutation);

  useEffect(() => {
    const run = async () => {
      const usersResult = await getAdmins();
      const usersData = usersResult?.data?.getAdmins ?? [];
      const transformedUsers = usersData.map(transformUser);

      setAdmins(transformedUsers);
    };

    run();
  }, []);

  const onAdminAdded = (admin: User) => {
    const newAdmin = transformUser(admin);
    setAdmins([...admins, newAdmin]);

    store.showSnackbar(messages.createSuccess);
  };

  const createAdmin = async (admin: ExtendedUser) => {
    try {
      const { data } = await createAdminApi({ input: { email: admin.email! } });
      onAdminAdded(data?.createAdmin);
    } catch (err) {
      store.showSnackbar(messages.createError);
    }
  };

  const getColumns = (): Column<ExtendedUser>[] => {
    const columns: any = [];

    columns.push({ title: 'ID', field: 'id', editable: 'never' });
    columns.push({
      title: 'Email',
      field: 'email',
      editable: 'always',
      validate: ({ email }) => !email || emailRegex.test(email),
    });

    return columns.filter((column) => !!column) as Column<any>[];
  };

  const onRowDelete = async (oldData: ExtendedUser) => {
    if (oldData?.id) {
      try {
        await deleteAdmin({ input: { id: oldData.id, isAdmin: true } });

        const data = [...admins];
        data.splice(data.indexOf(oldData), 1);
        setAdmins(data);
        store.showSnackbar(messages.deleteSuccess);
      } catch (error) {
        store.showSnackbar(messages.deleteError);
      }
    }

    return Promise.resolve();
  };

  const onRowAdd = (newData: ExtendedUser) => createAdmin(newData);

  const getTableTitle = (): string => `Vim Admins (${admins?.length})`;

  const tableOptions: Options<ExtendedUser> = {
    pageSize: 20,
    headerStyle: { backgroundColor: '#18202c', color: '#FFF' },
  };

  return (
    <MaterialTable
      title={getTableTitle()}
      icons={tableIcons}
      columns={getColumns()}
      data={admins}
      options={tableOptions}
      isLoading={getAdminsLoading}
      editable={{ onRowAdd, onRowDelete }}
    />
  );
};
