import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { isEmpty } from 'lodash-es';
import { ReducedAuth0User } from '../../../generated/graphql';

interface DeleteDialogProps {
  open: boolean;
  users: ReducedAuth0User[];
  onClose: () => void;
  onDelete: (userIds: string[]) => void;
}

export const DeleteDialog = ({ open, users, onDelete, onClose }: DeleteDialogProps) => {
  const handleDelete = () => {
    onDelete(users.map((user) => user.user_id));
    onClose();
  };

  return (
    <Dialog open={open && !isEmpty(users)} onClose={onClose}>
      <DialogTitle>Delete users</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete <b>{users.length}</b> users? This cannot be undone!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleDelete} variant="contained" color="secondary">
          Yes, delete it
        </Button>
      </DialogActions>
    </Dialog>
  );
};
