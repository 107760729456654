import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  TextField,
  withStyles,
  Button,
  WithStyles,
} from '@material-ui/core';
import { styles } from './style';
import { useApi } from '../../api/use-api';
import { MutationUpdateUserLinkedEhrUserArgs, SuccessStatus } from '../../generated/graphql';
import { updateUserLinkedEhrUserMutation } from '../../api/users';
import { store } from '../../stores/store';
import { messages } from '../users/messages';

interface LinkedEhrUserModalProps extends WithStyles<typeof styles> {
  userId?: string | null;
  organizationId?: number | null;
  email?: string | null;
  initialLinkedEhrUser?: string | null;
  isOpen: boolean;
  setIsOpen: (newData) => void;
  onSubmit: (newData) => void;
}

export const LinkedEhrUserDialog = withStyles(styles)(
  ({
    userId,
    organizationId,
    email,
    initialLinkedEhrUser,
    onSubmit,
    isOpen,
    setIsOpen,
    classes,
  }: LinkedEhrUserModalProps) => {
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
    const [isInputError, setIsInputError] = useState(false);
    const [linkedEhrUser, setLinkedEhrUser] = useState<string | null | undefined>(
      initialLinkedEhrUser,
    );

    useEffect(() => {
      setLinkedEhrUser(initialLinkedEhrUser);
    }, [initialLinkedEhrUser]);

    const [updateUserLinkedEhrUserApi, getUpdateUserLinkedEhrUserApiLoading] = useApi<
      MutationUpdateUserLinkedEhrUserArgs,
      SuccessStatus
    >(updateUserLinkedEhrUserMutation);

    const LOWER_CASE_TEXT = 'Only lowercase characters';
    const ERROR_TEXT = 'The user must have an email or EHR username';

    useEffect(() => {
      setIsSubmitButtonDisabled(
        // send request
        getUpdateUserLinkedEhrUserApiLoading ||
          // no valid input
          (!linkedEhrUser && !email) ||
          // no real changes
          linkedEhrUser === initialLinkedEhrUser,
      );
    }, [linkedEhrUser, email, initialLinkedEhrUser, getUpdateUserLinkedEhrUserApiLoading]);

    const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onLinkedEhrUserSubmit();
    };

    const onLinkedEhrUserSubmit = async () => {
      try {
        if (!linkedEhrUser && !email) {
          throw new Error(ERROR_TEXT);
        }
        await updateUserLinkedEhrUser();
        onSubmit(linkedEhrUser);
        setIsOpen(false);
        store.showSnackbar(messages.updateLinkedEhrUserSuccess);
      } catch (error: any) {
        const errorDisplayedMessage = createErrorMessage(error);
        store.showSnackbar(errorDisplayedMessage);
      }
    };

    const updateUserLinkedEhrUser = () => {
      return updateUserLinkedEhrUserApi({
        input: {
          id: userId!,
          linkedEhrUser,
        },
      });
    };

    const createErrorMessage = (error: any) => {
      const { message, name, extensions } = error;
      const errorName = name || extensions?.error?.name;
      const shouldUseDefaultErrorMessage = !(
        (errorName === 'GraphQLError' || !errorName) &&
        message
      );
      const errorDisplayedMessage = shouldUseDefaultErrorMessage
        ? messages.updateLinkedEhrUserError
        : message;
      return errorDisplayedMessage;
    };

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      const value = !event.target.value ? null : event.target.value.toLowerCase();
      setIsInputError(!value && !email);
      setLinkedEhrUser(value);
    };

    const handleOnClick = () => {
      setIsOpen(false);
      setIsInputError(false);
      setLinkedEhrUser(initialLinkedEhrUser);
    };

    return (
      <Dialog open={isOpen}>
        <form
          onSubmit={(event) => {
            handleOnSubmit(event);
          }}
          id="linked-ehr-user-edit-form"
          className={`${classes.dialogForm}`}
        >
          <div className={`${classes.inputsRow}`}>
            <TextField
              helperText={isInputError ? ERROR_TEXT : LOWER_CASE_TEXT}
              fullWidth
              error={isInputError}
              label="Linked Ehr User"
              className={`${classes.editInput}`}
              value={linkedEhrUser || ''}
              onChange={handleOnChange}
            />
          </div>
        </form>
        <DialogActions>
          <Button onClick={handleOnClick} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={isSubmitButtonDisabled}
            type="submit"
            form="linked-ehr-user-edit-form"
            variant="contained"
            color="primary"
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
