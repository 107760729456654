export const messages = {
  createSuccess: 'Created provider successfully',
  createError: 'Failed to create provider',
  deleteSuccess: 'Deleted provider successfully',
  deleteError: 'Failed to delete provider',
  deleteAllSuccess: 'Deleted all providers successfully',
  deleteAllError: 'Failed to delete all providers',
  deleteAreYouSure: 'Are you sure you want to delete all providers? This cannot be undone!',
  emptyFile: 'CSV file is empty, please review the documentation and try again.',
};
