import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import HighlightOff from '@material-ui/icons/HighlightOff';
import RotateRight from '@material-ui/icons/RotateRight';
import React from 'react';
import { IdentityProvider } from '../../../generated/graphql';

export enum SeamlessIndicationStatus {
  IGNORE_SSO = 'When it SSO the indication is not relevant',
  LOADING_STATUS = 'During get response about url',
  SEAMLESS_OK = 'The URL support seamless',
  SEAMLESS_SUGGESTS = 'The URL support seamless, but the provider not',
  SEAMLESS_WRONG = 'The URL not support seamless',
  NOT_RELEVANT = 'The provider is not seamless',
  EHR_NOT_SUPPORT = 'The EHR not support seamless verification',
}

export const getStatus = (
  ehrSupportSeamless: boolean,
  seamlessAvailable: boolean,
  seamlessLoading: boolean,
  identityProvider: IdentityProvider | undefined | null,
): SeamlessIndicationStatus => {
  if (!ehrSupportSeamless) {
    return SeamlessIndicationStatus.EHR_NOT_SUPPORT;
  }

  // for SSO there is no Seamless indication
  if (identityProvider === IdentityProvider.Auth0) {
    return SeamlessIndicationStatus.IGNORE_SSO;
  }

  // tell user that the system is checking if seamless available
  if (seamlessLoading) {
    return SeamlessIndicationStatus.LOADING_STATUS;
  }

  // show to the user that seamless is available
  // different color when not connected with seamless
  if (seamlessAvailable) {
    if (identityProvider === IdentityProvider.SeamlessLogin) {
      return SeamlessIndicationStatus.SEAMLESS_OK;
    }

    return SeamlessIndicationStatus.SEAMLESS_SUGGESTS;
  }

  // should be seamless but the url is not available
  if (identityProvider === IdentityProvider.SeamlessLogin) {
    return SeamlessIndicationStatus.SEAMLESS_WRONG;
  }

  return SeamlessIndicationStatus.NOT_RELEVANT;
};

const StatusToIcon: Partial<Record<SeamlessIndicationStatus, JSX.Element>> = {
  [SeamlessIndicationStatus.SEAMLESS_OK]: (
    <CheckCircleOutline
      titleAccess={SeamlessIndicationStatus.SEAMLESS_OK}
      style={{ color: 'green' }}
    />
  ),
  [SeamlessIndicationStatus.SEAMLESS_SUGGESTS]: (
    <CheckCircleOutline
      titleAccess={SeamlessIndicationStatus.SEAMLESS_SUGGESTS}
      style={{ color: 'orange' }}
    />
  ),
  [SeamlessIndicationStatus.LOADING_STATUS]: (
    <RotateRight
      className="spinner"
      titleAccess={SeamlessIndicationStatus.LOADING_STATUS}
      style={{ color: 'gray' }}
    />
  ),
  [SeamlessIndicationStatus.SEAMLESS_WRONG]: (
    <HighlightOff titleAccess={SeamlessIndicationStatus.SEAMLESS_WRONG} style={{ color: 'red' }} />
  ),
};

export const getSeamlessIcon = (status: SeamlessIndicationStatus): JSX.Element | null =>
  StatusToIcon[status] ?? null;
