import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { PreferredProvidersModalProps } from '../types';
import { deletePreferredProvidersMutation } from '../../../api/providers';
import { useApi } from '../../../api/use-api';
import { MutationDeleteAllProvidersArgs } from '../../../generated/graphql';
import { messages } from '../messages';
import { store } from '../../../stores/store';

export const DeleteAllProvidersModal = ({
  isOpen,
  onClose,
  organization,
  onFinish,
}: PreferredProvidersModalProps) => {
  const [deleteAllProviders] = useApi<MutationDeleteAllProvidersArgs, boolean>(
    deletePreferredProvidersMutation,
  );

  const handleOnClose = () => {
    onClose();
  };

  const handleDelete = () => {
    deleteAllProviders({ id: organization.id })
      .then(() => {
        store.showSnackbar(messages.deleteAllSuccess);
        onFinish?.();
      })
      .catch(() => {
        store.showSnackbar(messages.deleteAllError);
      })
      .finally(() => {
        handleOnClose();
      });
  };

  return (
    <Dialog onClose={handleOnClose} open={isOpen}>
      <DialogTitle>Delete all providers</DialogTitle>
      <DialogContent>
        <DialogContentText>{messages.deleteAreYouSure}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleOnClose}>
          Cancel
        </Button>
        <Button onClick={handleDelete} color="secondary" variant="contained" component="span">
          Yes, delete all
        </Button>
      </DialogActions>
    </Dialog>
  );
};
