import gql from 'graphql-tag';

export const getPreferredProvidersByOrgQuery = gql`
  query getPreferredProvidersByOrg($id: Int!) {
    getPreferredProvidersByOrg(id: $id) {
      id
      createdAt
      updatedAt
      npi
      firstName
      lastName
      locations {
        city
        state
        zip
        addressLine1
        latitude
        longitude
      }
    }
  }
`;

export const bulkUpsertPreferredProvidersMutation = gql`
  mutation BulkUpsertPreferredProviders($input: BulkPreferredProviderInput!) {
    bulkUpsertPreferredProviders(input: $input) {
      newProviders
      newLocations
    }
  }
`;

export const upsertPreferredProviderMutation = gql`
  mutation upsertPreferredProvider($input: ProviderInput!) {
    upsertPreferredProvider(input: $input) {
      id
      createdAt
      updatedAt
      npi
      firstName
      lastName
      location {
        city
        state
        zip
        addressLine1
        latitude
        longitude
      }
    }
  }
`;

export const deletePreferredProviderMutation = gql`
  mutation deletePreferredProvider($id: String!) {
    deletePreferredProvider(id: $id)
  }
`;
export const deletePreferredProvidersMutation = gql`
  mutation deletePreferredProvidersByOrg($id: Int!) {
    deletePreferredProvidersByOrg(id: $id)
  }
`;
