export enum Application {
  referralGuidance = 'referral-guidance',
  virtualScheduler = 'virtual-scheduler',
  patientChart = 'patient-chart',
  optumReferralUtility = 'optum-referral-utility',
  orderAssist = 'order-assist',
  eligibility = 'eligibility',
  scheduling = 'scheduling',
  optumDataCore = 'optum-data-core',
  optumPro = 'optum-pro',
  optumDataCoreDashboard = 'optum-data-core-dashboard',
  mockCanvasApp = 'mock-canvas-app',
  kahun = 'kahun',
  priviaInsights = 'privia-insights',
  cde = 'clinical-data-exchange',
}
