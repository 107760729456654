import { Theme, createStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    dialogForm: { display: 'flex', flexDirection: 'column', margin: '8px 24px' },
    inputsRow: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      marginTop: `${theme.spacing(2)}px`,
    },
    editInput: {
      marginRight: `${theme.spacing(3)}px`,
      minWidth: '270px',
    },
    emailPreviewInput: {
      '& input': {
        textOverflow: 'ellipsis',
      },
    },
  });
