import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './App';
import { updateAuth0Config } from './auth0/config';
import { featureFlagsClient, httpClient } from './services';
import { Team } from '@getvim/feature-flags';

const fetchAuth0Config = async () => {
  const useRestFF = await featureFlagsClient.getFlag({
    flagName: 'organization-console.shouldUseRestForPublicEndpoints',
    defaultValue: false,
    team: Team.OMT,
  });
  if (useRestFF) {
    const configResponse = await httpClient.get('/v1/config');
    return updateAuth0Config(configResponse.data.auth0);
  }

  const gql = JSON.stringify({
    query: `query getConfig {
        getConfig {
          auth0 {
            clientId
            domain
          }
        }
      }`,
  });

  const url = '/console-api/graphql';

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: gql,
  };

  const config = await fetch(url, requestOptions);

  const {
    data: {
      getConfig: { auth0 },
    },
  } = await config.json();

  updateAuth0Config(auth0);
};

(async () => {
  await fetchAuth0Config();
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
})();
