import { Theme, createStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 936,
      margin: 'auto',
      overflow: 'hidden',
    },
    searchBar: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: 'block',
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    contentWrapper: {
      margin: '40px 16px',
    },
    gridRoot: {
      display: 'grid',
      gap: `${theme.spacing(3)}px`,
    },
    userFormRoot: {
      margin: '8px 24px',
      gridTemplateColumns: 'auto',
    },
    oneColumn: {
      gridTemplateColumns: 'auto',
    },
    twoColumns: {
      gridTemplateColumns: 'auto auto',
    },
    organizationProductsRow: {
      gridTemplateColumns: '40% auto',
    },
    threeColumns: {
      gridTemplateColumns: 'auto auto auto',
    },
    checkboxField: {
      display: 'flex',
      justifyContent: 'end',
      '& label': {
        alignItems: 'end',
        '& span.MuiCheckbox-root': {
          paddingBottom: 0,
        },
      },
    },
    userPhoneNumber: {
      '& .atomic-label': {
        display: 'none',
      },
      '& input.userPhoneNumberInput[type="tel"]': {
        marginTop: '22px',
        lineHeight: '36px',
        height: '26px',
        fontSize: '16px',
        borderBottom: '1px solid #949494',
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      },
      '& input.userPhoneNumberInput[placeholder="Phone Number"]': {
        '--main-color': '#767676',
        fontStretch: '100%',
      },
    },
  });
