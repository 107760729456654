import { useCallback, useState, useRef, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { DocumentNode, GraphQLError, print } from 'graphql';
import { handleResponseError, throwErrorsFromResponse } from './utils/error';
import { gracefullyTryAsync } from './utils/utils';
import { useAuth0Wrapper } from '../useAuth0Wrapper';

type RunApiFunction<INPUT, OUTPUT> = (
  variables?: INPUT,
  accessToken?: string,
  isPublic?: boolean,
) => Promise<{ data: { [prop: string]: OUTPUT } }>;

export function useApi<INPUT, OUTPUT>(
  graphqlQuery: string | DocumentNode,
): [RunApiFunction<INPUT, OUTPUT>, boolean] {
  const isDisposed = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0Wrapper();
  const query = typeof graphqlQuery !== 'string' ? print(graphqlQuery) : graphqlQuery;

  const runApi = useCallback<RunApiFunction<INPUT, OUTPUT>>(
    async (variables, accessToken, isPublic = false) => {
      if (isDisposed.current) {
        return { data: undefined };
      }

      try {
        setIsLoading(true);
        const token = !isPublic
          ? accessToken ?? (await gracefullyTryAsync(getAccessTokenSilently, loginWithRedirect))
          : undefined;
        const result = await fetch('/console-api/graphql', {
          method: 'POST',
          headers: {
            ...(token ? { Authorization: token } : undefined),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ query, variables }),
        });

        const jsonResponse = await result.json();
        await throwErrorsFromResponse(jsonResponse);
        return jsonResponse;
      } catch (error) {
        await handleResponseError(error as GraphQLError, { loginWithRedirect });
      } finally {
        if (!isDisposed.current) {
          setIsLoading(false);
        }
      }
    },
    [getAccessTokenSilently, query],
  );

  useEffect(
    () => () => {
      isDisposed.current = true;
    },
    [],
  );

  return [runApi, isLoading];
}
