import { useCallback } from 'react';
import { useApi } from '../../api/use-api';
import { SendPasswordResetEmailInput, UserType } from '../../generated/graphql';
import { sendPasswordResetEmailMutation } from '../../api/users';
import { httpClient } from '../../services';

export interface PasswordResetResult {
  resetPassword(email: string, userTypes: UserType[], shouldUseRestAPIFF: boolean): void;
}

export const usePasswordReset = (): PasswordResetResult => {
  const [sendPasswordResetEmail] = useApi<
    { input: SendPasswordResetEmailInput },
    { status: string }
  >(sendPasswordResetEmailMutation);

  const resetPassword = useCallback(
    (email: string, userTypes: UserType[], shouldUseRestAPIFF: boolean) => {
      if (shouldUseRestAPIFF) {
        return httpClient.post('/v1/users/reset-password-email', { userTypes, email });
      }
      return sendPasswordResetEmail(
        {
          input: { userTypes, email },
        },
        undefined,
        true,
      );
    },
    [sendPasswordResetEmail],
  );

  return { resetPassword };
};
