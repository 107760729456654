import React from 'react';

interface SuccessfulConnectionInfoProps {
  userEmail: string;
}

export const SuccessfulConnectionInfo = ({ userEmail }: SuccessfulConnectionInfoProps) => {
  return (
    <div className="connection-info">
      <h1 className="connection-title success">Connection successful!</h1>
      {userEmail.length > 0 ? (
        <h4>User email identified: {userEmail}</h4>
      ) : (
        <h4>Failed to extract user email, check jwt to see properties</h4>
      )}
    </div>
  );
};
