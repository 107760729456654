import { PropTypes } from '@material-ui/core';
import { Organization, OrganizationProduct, AddressInput, Tags } from '../../generated/graphql';

export interface UserError {
  messages: string[];
  index: number;
  user: NewUserInput;
}

export interface NewUserInput {
  email: string;
  firstName: string;
  lastName: string;
  npi: string;
  tin: [string];
  title?: string;
  titles?: string[] | null;
  roles?: string[] | null;
  linkedEhrUser: string;
  products: string[] | null;
  organizationAdmin?: boolean | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  tag?: Tags;
  phoneNumber?: string | null;
}

export interface ProviderError {
  messages: string[];
  index: number;
  npi?: string;
}

export interface ProviderCSVRecord extends ProviderLocationInput {
  npi: string;
  firstName?: string;
  lastName?: string;
  specialty?: string[];
  tin?: string;
}

export interface NewProviderInput {
  npi: string;
  firstName?: string;
  lastName?: string;
  specialty?: string[];
  tin?: string;
  address?: string;
  location?: ProviderLocationInput;
}

export interface ProviderLocationInput {
  city?: string;
  state?: string;
  zip?: string;
  addressLine1?: string;
}

export interface ProviderModalButtonProps {
  organization: Organization;
  ModalComponent: React.FC<PreferredProvidersModalProps>;
  buttonText: string;
  onFinish?: () => void;
  startIcon?: React.ReactNode;
  color: PropTypes.Color;
}

export interface CsvUploaderProps {
  organization: Organization;
}

export enum SelectedUsers {
  AllUsers,
  UsersWithoutValue,
  None,
}

export interface TinUpdate {
  tin: string[];
  selectedUsers: SelectedUsers;
}

export interface UpdateOrganizationUsersBtnProps {
  organization: Organization;
  setProductsToAdd: (products: string[]) => void;
  setProductsToRemove: (products: string[]) => void;
  setTinUpdate: (tinUpdate: TinUpdate) => void;
}

export interface PreferredProvidersModalProps {
  organization: Organization;
  onClose: () => void;
  isOpen: boolean;
  onFinish?: () => void;
}

export interface UpdateOrganizationUsersModalProps {
  onClose: () => void;
  isOpen: boolean;
  products?: Array<OrganizationProduct>;
  setProductsToAdd: (products: string[]) => void;
  setProductsToRemove: (products: string[]) => void;
  setTinUpdate: (tinUpdate: TinUpdate) => void;
}

export interface ProductItem {
  name: string;
  action: ProductAction;
}

export enum ProductAction {
  Add,
  Unchanged,
  Remove,
}

export enum AddressAction {
  ChangeToAllUsers,
  ChangeToUsersWithoutAddress,
  Unchanged,
}

export interface SsoDebugProperties {
  redirectUrl: string;
}
