import gql from 'graphql-tag';

export const getAllOrganizationsQuery = gql`
  query getAllOrganizationsQuery {
    getAllOrganizations {
      count
      results {
        id
        accountId
        name
        ehrSystem
        ehrUrl
        organizationKey
        products {
          name
          displayName
          sources
        }
        namespaceName
        isLts
        tag
      }
    }
  }
`;

export const getAllNamespacesQuery = gql`
  query GetAllNamespaces {
    getAllNamespaces
  }
`;

export const findAllAccountsQuery = gql`
  query FindAllAccounts($input: FindAllAccountsInput) {
    findAllAccounts(input: $input) {
      id
      name
      relations {
        relationType
        relatedAccountId
      }
      dataSource {
        dataSourceEnum
      }
      config {
        enableCDEForOrg
      }
    }
  }
`;

export const getOrganizationQuery = gql`
  query getOrganizationQuery($id: Int!) {
    getOrganizationById(id: $id) {
      id
      name
      ehrSystem
      ehrOS
      ehrUrl
      ehrIdentifier
      products {
        name
        sources
        providerSources
        contentSources
      }
      config
      namespaceName
      slug
      identityProvider
      organizationKey
      tag
      theme
      tin
      tins
      npi
      ssoConnectionName
      isPopupsEnabled
      isSeamlessLoginAutoApproved
      isLts
      accountId
    }
  }
`;

export const getEhrSystemConfigurationsQuery = gql`
  query getEhrSystemConfigurations {
    getEhrSystemConfigurations {
      schemaName
      os
      disabledEhrUrl
    }
  }
`;

export const createAccountMutation = gql`
  mutation CreateAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      success
    }
  }
`;

export const updateProviderOrganizationMutation = gql`
  mutation UpdateProviderOrganization($input: UpdateProviderOrganizationInput!) {
    updateProviderOrganization(input: $input) {
      id
      name
      ehrSystem
      ehrUrl
      products {
        name
        displayName
        sources
        providerSources
        contentSources
      }
      namespaceName
      identityProvider
      tag
      accountId
      ehrIdentifier
    }
  }
`;

export const createProviderOrganizationMutation = gql`
  mutation CreateProviderOrganization($input: CreateProviderOrganizationInput!) {
    createProviderOrganization(input: $input) {
      id
      name
      ehrSystem
      ehrUrl
      products {
        name
        displayName
        sources
        providerSources
        contentSources
      }
      namespaceName
      identityProvider
      tag
      accountId
      ehrIdentifier
    }
  }
`;

export const addProductsToOrganizationUsersMutation = gql`
  mutation addProductsToOrganizationUsers($input: AddProductsToOrganizationUsersInput!) {
    addProductsToOrganizationUsers(input: $input)
  }
`;

export const removeProductsFromOrganizationUsersMutation = gql`
  mutation removeProductsFromOrganizationUsers($input: RemoveProductsFromOrganizationUsersInput!) {
    removeProductsFromOrganizationUsers(input: $input)
  }
`;

export const updateOrgUsersTinMutation = gql`
  mutation updateOrgUsersTin($input: UpdateOrgUsersTinInput!) {
    updateOrgUsersTin(input: $input)
  }
`;

export const deleteOrganizationMutation = gql`
  mutation deleteOrg($id: Int!) {
    deleteOrganization(id: $id)
  }
`;

export const createVimSignatureMutation = gql`
  mutation createConsoleVimSignature($input: CreateConsoleVimSignatureInput) {
    createConsoleVimSignature(input: $input) {
      signatures {
        vimSignature
        appId
      }
    }
  }
`;
