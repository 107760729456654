import {
  Box,
  Button,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  withStyles,
  WithStyles,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import ZoomIn from '@material-ui/icons/ZoomIn';
import { isEmpty, snakeCase, startCase } from 'lodash-es';
import MaterialTable, { Column, Query, QueryResult } from 'material-table';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { AffectedField } from '@getvim/platform-types';
import { useApi } from '../../api/use-api';

import { tableIcons } from '../../assets/material-table-icons';
import { QueryGetAuditsArgs } from '../../generated/graphql';
import { store } from '../../stores/store';
import { messages } from './messages';
import { styles } from './style';
import './audit.less';
import { AuditLog } from '../../common/audit/types';

import { getAuditsQuery } from '../../api/audit';
import { JsonDialog } from './dialog/JsonDialog';

export const Audit = withStyles(styles)(({ classes }: WithStyles<typeof styles>) => {
  const EMPTY_TABLE: QueryResult<AuditLog> = { data: [], page: 0, totalCount: 0 };
  const defaultSearchQuery = 'organizations';

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchBy, setSearchBy] = useState<string>('appName');

  const [isNewSearch, setIsNewSearch] = useState<boolean>(false);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState();
  const [dialogTitle, setDialogTitle] = useState<string>('');

  const [getAuditPagination] = useApi<QueryGetAuditsArgs, AuditLog[]>(getAuditsQuery);

  const materialTableRef = useRef(null);

  useEffect(() => {
    if (!dialogOpen) {
      setDialogData(undefined);
      setDialogTitle('');
    }
  }, [dialogOpen]);

  const dialogOpenHandler = (json: any, title: string) => {
    setDialogTitle(title);
    setDialogData(json);
    setDialogOpen(true);
  };

  const snakeCaseIfNeeded = (searchQuery: string, filter: string) => {
    if (filter === 'actionName') {
      return snakeCase(searchQuery);
    }
    return searchQuery;
  };

  const getColumns = () => {
    const columns: any = [];

    columns.push(
      {
        title: 'Created (UTC)',
        field: 'createdAt',
        render: (audit: AuditLog) => (
          <div style={{ textAlign: 'center' }}>
            {moment(audit.createdAt).utc().format('MM/DD/YYYY - HH:mm:ss')}
          </div>
        ),
      },
      {
        title: 'App Name',
        field: 'appName',
      },
      {
        title: 'Action Name',
        field: 'actionName',
        render: (audit: AuditLog) => <div>{startCase(audit.actionName.replace('_', ' '))}</div>,
      },
      {
        title: 'Acting User',
        field: 'actingUser',
        sorting: false,
        render: (audit: AuditLog) => (
          <IconButton
            onClick={() => dialogOpenHandler(audit.actingUser, 'Acting User')}
            size="small"
          >
            <ZoomIn />
            View
          </IconButton>
        ),
      },
      {
        title: 'Acting User Name',
        sorting: false,
        render: (audit: AuditLog) => <div>{audit.actingUser.id.readableId}</div>,
      },
      {
        title: 'Affected Entity',
        field: 'affectedEntity',
        sorting: false,
        render: (audit: AuditLog) => (
          <IconButton
            onClick={() => dialogOpenHandler(audit.affectedEntity, 'Affected Entity')}
            size="small"
          >
            <ZoomIn />
            View
          </IconButton>
        ),
      },
      {
        title: 'Affected Name',
        sorting: false,
        render: (audit: AuditLog) => <div>{audit.affectedEntity.id.readableId}</div>,
      },
      {
        title: 'Affected ID',
        sorting: false,
        render: (audit: AuditLog) => <div>{audit.affectedEntity.id.technicalId}</div>,
      },
      {
        title: 'Affected Organization',
        sorting: false,
        render: (audit: AuditLog) => <div>{audit.affectedEntity.organization}</div>,
      },
      {
        title: 'Source IP',
        field: 'sourceIp',
        sorting: false,
      },
      {
        title: 'Action Result',
        field: 'actionResult',
        sorting: false,
      },
    );

    return columns.filter((column) => !!column) as Column<any>[];
  };

  const parseAudit = (postAuditLog: AuditLog[]): AuditLog[] => {
    return postAuditLog?.map((auditLog: AuditLog) => {
      const { affectedFields } = auditLog.affectedEntity;
      const affectedFieldsNew = affectedFields?.map((affectedField: AffectedField) => {
        const newValue = affectedField?.newValue ? JSON.parse(affectedField?.newValue) : null;
        const previousValue = affectedField?.previousValue
          ? JSON.parse(affectedField?.previousValue)
          : null;

        return { ...affectedField, newValue, previousValue };
      });
      return {
        ...auditLog,
        affectedEntity: { ...auditLog.affectedEntity, affectedFields: affectedFieldsNew },
      };
    });
  };

  const getTableData = async (query: Query<AuditLog>): Promise<QueryResult<AuditLog>> => {
    try {
      const { error, page, pageSize: limit, orderDirection, orderBy } = query;

      if (error) store.showSnackbar(error.message);

      const q = snakeCaseIfNeeded(searchQuery || defaultSearchQuery, searchBy);

      const { data } = await getAuditPagination({
        input: {
          page: isNewSearch ? 0 : page,
          limit,
          filters: { [searchBy]: q },
          order: orderDirection || 'desc',
          sortBy: orderBy?.field || 'createdAt',
        },
      });

      const audits = data?.getAudits;
      const parsedAudits = parseAudit(audits);

      setIsNewSearch(false);

      if (isEmpty(audits)) {
        store.showSnackbar(messages.emptyResult);
        return EMPTY_TABLE;
      }

      return {
        data: parsedAudits,
        totalCount: (parsedAudits && parsedAudits[0].totalCount) || 0,
        page: isNewSearch ? 0 : page,
      };
    } catch (error) {
      store.showSnackbar(messages.searchError);
      return EMPTY_TABLE;
    }
  };

  const onQueryChange = () => (materialTableRef.current as any).onQueryChange();

  return (
    <>
      <Box className={classes.box}>
        <TextField
          label="Search for Audits"
          variant="outlined"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setIsNewSearch(true);
              onQueryChange();
            }
          }}
          value={searchQuery}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
          className={classes.flexGrow}
        />
        <FormControl variant="outlined" className={classes.flexGrow}>
          <InputLabel>Search by</InputLabel>
          <Select
            label="Search by"
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value as string)}
          >
            <MenuItem value="appName">App Name</MenuItem>
            <MenuItem value="actionName">Action Name</MenuItem>
            <MenuItem value="affectedID">Affected ID</MenuItem>
            <MenuItem value="affectedName">Affected Name</MenuItem>
            <MenuItem value="organizationID">Organization ID</MenuItem>
            <MenuItem value="actingUser">Acting User</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          startIcon={<CloseIcon />}
          className={classes.button}
          onClick={() => {
            setSearchBy('appName');
            setSearchQuery('');
            setIsNewSearch(true);
            onQueryChange();
          }}
        >
          Reset
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<SearchIcon />}
          className={classes.button}
          onClick={() => {
            setIsNewSearch(true);
            onQueryChange();
          }}
        >
          Search
        </Button>
      </Box>
      <MaterialTable
        icons={tableIcons}
        columns={getColumns()}
        data={getTableData}
        tableRef={materialTableRef}
        options={{
          pageSize: 10,
          sorting: true,
          pageSizeOptions: [],
          search: false,
          showTitle: false,
          draggable: false,
          toolbar: false,
          headerStyle: { backgroundColor: '#18202c', color: '#FFF' },
        }}
      />
      <JsonDialog
        open={dialogOpen}
        data={dialogData}
        title={dialogTitle}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
});
