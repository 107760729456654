import React, { ChangeEvent, FunctionComponent } from 'react';
import InputStyleWrapper, { InputStyle } from '../inputStyleWrapper';
import { stringToPhoneNumber, valuePhoneNumber } from '../../lib/phoneFormatter';
import { PhoneNumberType } from '../../types';
import LabeledInput from '../labeledInput';

const PHONE_MAX_LENGTH = 11;

type InputType = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>;

export interface PhoneNumberInputProps {
  onChange: (phoneNumber: PhoneNumberType | undefined) => void;
  value: PhoneNumberType;
  active?: boolean;
  label: string;
}

function onInputChange(
  event: ChangeEvent<HTMLInputElement>,
  originalOnChange: (event: any) => void,
): void {
  let sanitizedValue = event.target.value.replace(/\D/g, '');

  if (sanitizedValue.length > PHONE_MAX_LENGTH) {
    sanitizedValue = sanitizedValue.substr(0, PHONE_MAX_LENGTH);
  }

  let phoneNumber = stringToPhoneNumber(sanitizedValue);

  if (!phoneNumber) {
    phoneNumber = { number: sanitizedValue, countryDialingCode: '' };
  }

  originalOnChange(phoneNumber);
}

const PhoneNumberInput: FunctionComponent<PhoneNumberInputProps & InputType> = ({
  onChange,
  value,
  active,
  label,
  id,
  ...input
}) => (
  <InputStyleWrapper inputStyle={InputStyle.clean}>
    <LabeledInput
      id={id}
      htmlFor={id}
      label={label}
      type="tel"
      active={active}
      onChange={(newValue) => onInputChange(newValue, onChange)}
      value={valuePhoneNumber(value.number || '') || value.number}
      {...input}
    />
  </InputStyleWrapper>
);

export default PhoneNumberInput;
