import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { IdentityProvider } from '../../generated/graphql';

const style = {
  display: 'block',
  width: 400,
  marginTop: 30,
};

export interface IdentityFormProps {
  identityProvider?: IdentityProvider;
  onSelect(identityProvider: IdentityProvider | null): void;
}

const identityProviderEntries = Object.entries(IdentityProvider).map(([key, value]) => (
  <MenuItem value={value} key={key}>
    {value}
  </MenuItem>
));

export const IdentityProviderForm = ({ identityProvider, onSelect }: IdentityFormProps) => (
  <FormControl variant="outlined" style={style}>
    <InputLabel>Identity Provider</InputLabel>
    <Select
      value={identityProvider ?? ''}
      onChange={(event) =>
        onSelect(event.target.value === '' ? null : (event.target.value as IdentityProvider))
      }
      label="Identity Provider"
    >
      <MenuItem value="" key="none">
        None
      </MenuItem>
      {identityProviderEntries}
    </Select>
  </FormControl>
);

IdentityProviderForm.defaultProps = {
  identityProvider: '',
};
