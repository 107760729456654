import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

interface NoDataSourcesDialogProps {
  open: boolean;
  onSave: () => void;
  onCancel: () => void;
  appNames?: string[];
}

export const NoDataSourcesDialog = ({
  open,
  appNames,
  onSave,
  onCancel,
}: NoDataSourcesDialogProps) => {
  return (
    <Dialog open={open}>
      <DialogTitle className="dialog-message-title">
        <span>Save Organization</span>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {appNames?.length === 1
            ? `Please note app ${appNames[0]} is saved with no Data sources configured`
            : 'Some of your applications are saved without sources'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSave()} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
