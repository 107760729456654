// This code is essentially copied from a package in namespaces called "@vim/address-manipulation"
export interface FullAddress {
  addressLine1: string | null;
  addressLine2?: string | null;
  city: string | null;
  state: string | null;
  zip?: string | null;
}

export function addressFormat(address: Partial<FullAddress>): string {
  let stringAddress = '';
  if (!address) {
    return stringAddress;
  }
  if (address.addressLine1) {
    stringAddress = address.addressLine1;
  }

  if (address.addressLine2) {
    stringAddress += `${stringAddress ? ', ' : ''}${address.addressLine2}`;
  }
  if (address.city) {
    stringAddress += `${stringAddress ? ', ' : ''}${address.city}`;
  }
  if (address.state) {
    stringAddress += `${stringAddress ? ', ' : ''}${address.state}`;
  }
  if (address.zip) {
    stringAddress += `${stringAddress ? ' ' : ''}${address.zip}`;
  }
  return stringAddress;
}
