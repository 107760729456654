import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { observer } from 'mobx-react';
import { store } from '../../stores/store';

export const InfoSnackbar = observer(() => {
  const close = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    store.snackbarVisible = false;
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={store.snackbarVisible}
      autoHideDuration={3000}
      onClose={close}
      message={store.snackbarMessage}
    />
  );
});
