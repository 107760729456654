import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

interface MessageDialogProps {
  open: boolean;
  message: string;
  title?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const MessageDialog = ({
  open,
  message,
  title,
  onConfirm,
  onCancel,
}: MessageDialogProps) => {
  return (
    <Dialog open={open}>
      <DialogTitle className="dialog-message-title">
        <span>{title}</span>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onConfirm()} variant="contained" color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
