import React, { useState, useMemo, useEffect } from 'react';
import {
  Dialog,
  TextField,
  withStyles,
  WithStyles,
  IconButton,
  InputAdornment,
  DialogActions,
  Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { styles } from './style';
import { AddressInput as AddressInputType } from '../../generated/graphql';
import { addressFormat } from './logic';

interface AddressDialogProps extends WithStyles<typeof styles> {
  open: boolean;
  initialAddress?: Partial<AddressInputType> | null;
  onClose: () => void;
  onSubmit: (newData) => void;
}

interface AddressInputProps extends WithStyles<typeof styles> {
  onSubmit: (address) => void;
  initialAddress?: Partial<AddressInputType> | null;
}

const AddressModal = withStyles(styles)(
  ({ open, onClose, onSubmit, initialAddress, classes }: AddressDialogProps) => {
    const parseAddress = (initialAddress: Partial<AddressInputType> | null | undefined) =>
      initialAddress
        ? {
            addressLine1: initialAddress.addressLine1,
            addressLine2: initialAddress.addressLine2,
            city: initialAddress.city,
            state: initialAddress.state,
            zip: initialAddress.zip,
          }
        : null;

    const [address, setAddress] = useState<AddressInputType | null>(parseAddress(initialAddress));

    useEffect(() => {
      setAddress(parseAddress(initialAddress));
    }, [initialAddress]);

    return (
      <Dialog open={open}>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onSubmit({ ...address });
          }}
          id="address-edit-form"
          className={`${classes.dialogForm}`}
        >
          <div className={`${classes.inputsRow}`}>
            <TextField
              fullWidth
              label="Address Line 1"
              className={`${classes.leftInput}`}
              value={address?.addressLine1 || ''}
              onChange={(event) => {
                const value = !event.target.value ? null : event.target.value;

                setAddress({ ...address, addressLine1: value });
              }}
            />
            <TextField
              fullWidth
              label="Address Line 2"
              value={address?.addressLine2 || ''}
              onChange={(event) => {
                const value = !event.target.value ? null : event.target.value;
                setAddress({ ...address, addressLine2: value });
              }}
            />
          </div>
          <div className={`${classes.inputsRow}`}>
            <TextField
              fullWidth
              className={`${classes.leftInput}`}
              label="City"
              value={address?.city || ''}
              onChange={(event) => {
                const value = !event.target.value ? null : event.target.value;
                setAddress({ ...address, city: value });
              }}
            />
            <TextField
              fullWidth
              label="State"
              value={address?.state || ''}
              onChange={(event) => {
                const value = !event.target.value ? null : event.target.value;
                setAddress({ ...address, state: value });
              }}
            />
          </div>
          <div className={`${classes.inputsRow}`}>
            <TextField
              fullWidth
              label="Zip"
              value={address?.zip || ''}
              onChange={(event) => {
                const value = !event.target.value ? null : event.target.value;
                setAddress({ ...address, zip: value });
              }}
            />
          </div>
        </form>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            form="address-edit-form"
            variant="contained"
            color="primary"
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

export const AddressInput = withStyles(styles)(
  ({ onSubmit, initialAddress, classes }: AddressInputProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const fullAddress = useMemo(() => (initialAddress ? addressFormat(initialAddress) : null), [
      initialAddress,
    ]);

    const onEditButtonClicked = () => {
      setIsOpen(true);
    };

    return (
      <>
        <TextField
          fullWidth
          label="Address"
          value={fullAddress}
          className={`${classes.addressPreviewInput}`}
          InputProps={{
            disabled: true,
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Edit Address"
                  onClick={onEditButtonClicked}
                  onMouseDown={(event) => event.preventDefault()}
                >
                  <EditIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <AddressModal
          open={isOpen}
          initialAddress={initialAddress}
          onClose={() => setIsOpen(false)}
          onSubmit={(address) => {
            setIsOpen(false);
            onSubmit(address);
          }}
        />
      </>
    );
  },
);
