import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { CsvUploadModal } from './CsvUploadModal';
import { CsvUploaderProps } from '../types';

export const CsvUploader = ({ organization }: CsvUploaderProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAddUsersClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Button color="primary" variant="contained" component="span" onClick={handleAddUsersClick}>
        Load users (.csv)
      </Button>

      <CsvUploadModal isOpen={isModalOpen} onClose={handleModalClose} organization={organization} />
    </>
  );
};
