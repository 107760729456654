import { User } from '../../generated/graphql';
import { ExtendedUser } from './types';

export const extractCommonInput = (newData) => ({
  products: newData.products,
  npi: newData.npi,
  tin: newData.tin,
  title: newData.title,
  titles: newData.titles,
  roles: newData.roles,
  address: newData?.address
    ? {
        addressLine1: newData.address.addressLine1,
        addressLine2: newData.address.addressLine2,
        city: newData.address.city,
        state: newData.address.state,
        zip: newData.address.zip,
      }
    : null,
  firstName: newData.firstName,
  lastName: newData.lastName,
  tag: newData.tag,
  phoneNumber: newData.phoneNumber,
  isOrgAdmin: newData.isOrgAdmin,
});

export const transformUser = (user: User): ExtendedUser => {
  return {
    ...user,
    organization: user?.ehrUser?.organization,
    products: user?.ehrUser?.products?.map(({ name }) => name) ?? [],
    tag: user?.ehrUser?.tag,
    npi: user?.ehrUser?.npi,
    tin: user?.ehrUser?.tin,
    title: user?.ehrUser?.title,
    titles: user?.ehrUser?.titles,
    roles: user?.ehrUser?.roles,
    address: user?.ehrUser?.address,
    firstName: user?.ehrUser?.firstName,
    lastName: user?.ehrUser?.lastName,
    linkedEhrUser: user?.ehrUser?.linkedEhrUser,
    isOrgAdmin: user?.isOrgAdmin,
    phoneNumber: user?.phoneNumber,
  };
};

export const transformUserToSubmit = (user: ExtendedUser | undefined): ExtendedUser => ({
  ...user,
  linkedEhrUser: user?.linkedEhrUser?.trim(),
});
