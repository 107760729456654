import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { getFullOrganizationConsoleUrl } from '../../common/env';

const NotSupported = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{'Not Supported'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Organization creation/edit is available{' '}
          <a
            href={getFullOrganizationConsoleUrl()}
            style={{ textDecoration: 'underline', fontWeight: 'bold' }}
          >
            here
          </a>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotSupported;
