import React from 'react';

interface FailedConnectionInfoProps {
  errorName: string;
  errorMessage: string;
}

export const FailedConnectionInfo = ({ errorName, errorMessage }: FailedConnectionInfoProps) => {
  return (
    <div className="connection-info">
      <h1 className="connection-title failure">Connection failed!</h1>
      <h4>{errorName ? `${errorName}: ${errorMessage}` : 'Failed to extract error message'}</h4>
    </div>
  );
};
