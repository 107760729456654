import React, { useEffect, useMemo, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import MaterialTable, { Column } from 'material-table';
import moment from 'moment';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import Link from '@material-ui/core/Link';
import { tableIcons } from '../../../assets/material-table-icons';
import { NewProviderInput, PreferredProvidersModalProps } from '../types';
import { useApi } from '../../../api/use-api';
import {
  deletePreferredProviderMutation,
  getPreferredProvidersByOrgQuery,
  upsertPreferredProviderMutation,
} from '../../../api/providers';
import { MutationUpsertProviderArgs, Provider } from '../../../generated/graphql';
import { useSafeState } from '../../../common/use-safe-state';
import { ProviderModalButton } from './provider-upload-button';
import { ProviderCsvUploadModal } from './provider-csv-upload-modal';
import { store } from '../../../stores/store';
import { ProviderDetailsDialog } from './provider-details-dialog';
import { messages } from '../messages';
import { DeleteAllProvidersModal } from './provider-delete-modal';
import { featureFlagsClient } from '../../../services';

const GQL_ERROR_NAME = 'GraphQLError';

export const PreferredProvidersModal = ({
  organization,
  isOpen,
  onClose,
}: PreferredProvidersModalProps) => {
  const [getProviders, getProvidersLoading] = useApi<{ id: number }, Provider[]>(
    getPreferredProvidersByOrgQuery,
  );
  const [createProviderApi] = useApi<MutationUpsertProviderArgs, Provider>(
    upsertPreferredProviderMutation,
  );
  const [displayDeleteAllProvidersButton, setDisplayDeleteAllProvidersButton] = useSafeState<
    boolean
  >(false);
  const [deleteProvider] = useApi<{ id: string }, boolean>(deletePreferredProviderMutation);
  const [providers, setProviders] = useSafeState<Provider[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogProvider, setDialogProvider] = useState<NewProviderInput>();
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(false);
  const [upsertProviderLoading, setUpsertLoading] = useState<boolean>(false);
  const isLoading = useMemo(() => getProvidersLoading || upsertProviderLoading, [
    getProvidersLoading,
    upsertProviderLoading,
  ]);
  const customizeDeleteText =
    'Are you sure? Deleting this row will delete it, as well as all associated rows with this NPI';

  const flatProviderLocations = (provider): Provider[] => {
    const { locations, ...rest } = provider;
    if (!locations || locations?.length === 0) return [rest];
    return provider.locations.map((location) => ({ ...rest, location })) || [];
  };

  const fetchProviders = async () => {
    const response = await getProviders({ id: organization.id ?? 1 });
    const providersData = response?.data?.getPreferredProvidersByOrg;
    const formattedProvider = formatProvider(providersData);
    if (formattedProvider) {
      setProviders(formattedProvider);
    }
  };

  const formatProvider = (providersData) => {
    const providersArray: Provider[] = [];
    if (providersData) {
      providersData.forEach((provider) => {
        return providersArray.push(...flatProviderLocations(provider));
      });
    }
    return providersArray;
  };

  const createProvider = async (newData: NewProviderInput) => {
    try {
      setUpsertLoading(true);
      const addedProvider = {
        ...newData,
        orgId: organization.id as number,
      };
      await createProviderApi({
        input: addedProvider,
      });
      await fetchProviders();
      store.showSnackbar(messages.createSuccess);
    } catch (e) {
      console.log(`Failed to create provider `, e);

      store.showSnackbar(messages.createError);
    } finally {
      setUpsertLoading(false);
    }
  };
  const onProviderSubmit = async (newData) => {
    try {
      setIsSubmitButtonDisabled(true);
      await createProvider(newData);
      setDialogOpen(false);
    } catch (error: any) {
      console.log(`Failed to create provider `, error);

      const errorMessage = error?.message;
      const errorDisplayedMessage =
        error?.name === GQL_ERROR_NAME && errorMessage ? errorMessage : messages.createError;
      store.showSnackbar(errorDisplayedMessage);
    } finally {
      setUpsertLoading(false);
      setIsSubmitButtonDisabled(false);
    }
  };

  const getColumns = () => {
    const columns: Column<Provider>[] = [];
    columns.push(
      {
        title: 'Created',
        field: 'createdAt',
        render: (provider: Provider) => {
          if (!provider.createdAt) {
            return <div>--</div>;
          }
          return <div>{moment(provider.createdAt).format('MM/DD/YYYY')}</div>;
        },
      },
      {
        title: 'Last modified',
        field: 'updatedAt',
        render: (provider: Provider) => {
          if (!provider.updatedAt) {
            return <div>--</div>;
          }
          return <div>{moment(provider.updatedAt).format('MM/DD/YYYY')}</div>;
        },
      },
      {
        title: 'NPI',
        field: 'npi',
      },
      {
        title: 'First name',
        field: 'firstName',
      },
      {
        title: 'Last name',
        field: 'lastName',
      },
      {
        title: 'Address Line 1',
        field: 'location.addressLine1',
      },
      {
        title: 'State',
        field: 'location.state',
      },
      {
        title: 'Zip',
        field: 'location.zip',
      },
      {
        title: 'City',
        field: 'location.city',
      },
    );
    return columns.filter((column) => !!column) as Column<Provider>[];
  };
  const getActions = () => {
    return [
      {
        isFreeAction: true,
        tooltip: 'Add',
        icon: () => <AddIcon />,
        onClick: () => setDialogOpen(true),
      },
    ];
  };
  useEffect(() => {
    fetchProviders();
    const getFeatureFlag = async () => {
      const isDeleteAllProvidersFeatureFlagEnabled = await featureFlagsClient.getFlag({
        flagName: 'deleteAllProvidersButton',
        defaultValue: false,
      });

      setDisplayDeleteAllProvidersButton(isDeleteAllProvidersFeatureFlagEnabled);
    };

    getFeatureFlag();
  }, []);

  useEffect(() => {
    if (!dialogOpen) {
      setDialogProvider(undefined);
    }
  }, [dialogOpen]);

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={isOpen}>
      <Box display="flex" justifyContent="space-between" alignItems="center" paddingRight="1rem">
        <Box display="flex" alignItems="center">
          <DialogTitle>{organization.name} - Preferred providers </DialogTitle>
          <Link
            href="https://bookmd.atlassian.net/wiki/spaces/WIT/blog/2023/03/26/3103850855/Feature+release+Preferred+provider+per+specific+site+of+service+-+WIP"
            underline="always"
            target="_blank"
            color="primary"
            className="documentation-link"
          >
            Documentation
          </Link>
        </Box>
        <Box>
          <ProviderModalButton
            buttonText="Load preferred providers (.csv)"
            organization={organization}
            ModalComponent={ProviderCsvUploadModal}
            onFinish={fetchProviders}
            color="primary"
          />
          {displayDeleteAllProvidersButton && (
            <ProviderModalButton
              buttonText="Delete all"
              organization={organization}
              ModalComponent={DeleteAllProvidersModal}
              onFinish={() => setProviders([])}
              startIcon={<tableIcons.Delete />}
              color="secondary"
            />
          )}

          <IconButton onClick={onClose}>
            <tableIcons.Close />
          </IconButton>
        </Box>
      </Box>
      <DialogContent dividers>
        <MaterialTable
          title=""
          icons={tableIcons}
          columns={getColumns()}
          data={providers}
          isLoading={isLoading}
          options={{ filtering: true }}
          actions={getActions()}
          localization={{
            body: {
              editRow: {
                deleteText: customizeDeleteText,
              },
            },
          }}
          editable={{
            onRowDelete: (oldData) => {
              if (oldData?.id) {
                return deleteProvider({ id: oldData.id })
                  .then(() => {
                    fetchProviders();
                    store.showSnackbar(messages.deleteSuccess);
                  })
                  .catch((e) => {
                    console.log(`Failed to create provider `, e);
                    store.showSnackbar(messages.deleteError);
                  });
              }

              return Promise.resolve();
            },
          }}
        />
      </DialogContent>

      <ProviderDetailsDialog
        open={dialogOpen}
        initialProvider={dialogProvider}
        onClose={() => setDialogOpen(false)}
        onSubmit={onProviderSubmit}
        isSubmitButtonDisabled={isSubmitButtonDisabled}
      />
    </Dialog>
  );
};
