import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import { ThemeProvider, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useCallback, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Audit } from '../audit/audit';
import { Header } from '../header/header';
import { InfoSnackbar } from '../info-snackbar/info-snackbar';
import { Navigator } from '../navigator/navigator';
import { OrganizationDetails } from '../organization-details/OrganizationDetails';
import { Organizations } from '../organizations/Organizations';
import { Users } from '../users/users';
import { Investigator } from '../investigator/investigator';
import { Admins } from '../users/admins';
import { OrganizationAdmins } from '../users/organization-admins';
import { drawerWidth, getTheme, styles } from './style';
import { useEnableInvestigatorPage } from '../../common/use-enable-investigator';
import { SeamlessVerification } from '../seamless-verification';
import { QUERY_PARAMS } from '../../common/queryParams';
import { useAuth0Wrapper } from '../../useAuth0Wrapper';
import { Team, useFeatureFlag } from '@getvim/feature-flags-react';
import { Loader } from '@getvim/atomic-ui';
import StopLookingAtTheCodeAndGoToNewConsole from '../common/StopLookingAtTheCodeAndGoToNewConsole';
import { getFullOrganizationConsoleUrl } from '../../common/env';

export type MainProps = WithStyles<typeof styles>;

export const Main = withStyles(styles)((props: MainProps) => {
  const { classes } = props;
  const { search } = useLocation();
  const { user, token } = useAuth0Wrapper();
  const isEmbedded = new URLSearchParams(search).get(QUERY_PARAMS.IS_EMBEDDED) === 'true';

  const [mobileOpen, setMobileOpen] = useState(false);

  const [shouldBlockConsoleFF, isLoadingShouldBlockConsoleFF] = useFeatureFlag({
    flagName: 'shouldBlockOldConsole',
    defaultValue: false,
    flagContext: { vimUserEmail: user?.email },
    team: Team.OMT,
  });

  const [shouldUseUniversalLogin] = useFeatureFlag({
    flagName: 'console.shouldUseUniversalLogin',
    defaultValue: false,
    team: Team.OMT,
  });

  const enableInvestigatorPage = useEnableInvestigatorPage();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const shouldBlockConsole = useMemo(() => {
    if (shouldBlockConsoleFF && user?.email && !token) {
      return true;
    }
    return false;
  }, [user, token, shouldBlockConsoleFF]);

  const OnGoToNewConsoleClicked = useCallback(() => {
    const newConsoleUrl = getFullOrganizationConsoleUrl(shouldUseUniversalLogin);
    window.location.replace(newConsoleUrl);
    console.log('🥳🥳🥳🥳');
  }, [shouldUseUniversalLogin]);

  if (isLoadingShouldBlockConsoleFF) {
    return <Loader className="center" />;
  }

  return (
    <ThemeProvider theme={getTheme()}>
      {shouldBlockConsole ? (
        <StopLookingAtTheCodeAndGoToNewConsole
          isOpen={true}
          onGoToNewConsole={OnGoToNewConsoleClicked}
        />
      ) : (
        <>
          <div className={classes.root}>
            <CssBaseline />
            {!isEmbedded && (
              <nav className={classes.drawer}>
                <Hidden smUp implementation="js">
                  <Navigator
                    PaperProps={{ style: { width: drawerWidth } }}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                  />
                </Hidden>
                <Hidden xsDown implementation="css">
                  <Navigator PaperProps={{ style: { width: drawerWidth } }} />
                </Hidden>
              </nav>
            )}
            <div className={`${classes.app} ${isEmbedded ? classes.fullScreen : ''}`}>
              {!isEmbedded && <Header onDrawerToggle={handleDrawerToggle} />}
              <main className={classes.main}>
                <Switch>
                  <Route exact path="/organizations">
                    <Organizations />
                  </Route>
                  <Route exact path="/organization/new" component={OrganizationDetails} />
                  <Route exact path="/organization/:id" component={OrganizationDetails} />
                  <Route exact path="/users">
                    <Users />
                  </Route>
                  <Route exact path="/admins">
                    <Admins />
                  </Route>
                  <Route exact path="/organization-admins">
                    <OrganizationAdmins />
                  </Route>
                  <Route exact path="/">
                    <Redirect to="/organizations" />
                  </Route>
                  <Route exact path="/audit">
                    <Audit />
                  </Route>
                  {enableInvestigatorPage && (
                    <>
                      <Route exact path="/investigator">
                        <Investigator />
                      </Route>
                      <Route exact path="/seamless-verification">
                        <SeamlessVerification />
                      </Route>
                    </>
                  )}
                </Switch>
              </main>
              {!isEmbedded && (
                <footer className={classes.footer}>
                  <Copyright />
                </footer>
              )}
            </div>
          </div>
          <InfoSnackbar />
        </>
      )}
    </ThemeProvider>
  );
});

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`Vim © ${new Date().getFullYear()} | `}
      <Link color="inherit" href="https://getvim.com/legal/website-terms-of-use/">
        Terms of Use
      </Link>
    </Typography>
  );
};
