interface MessagePayloadMap {
  'vim-ehr-login-ready': undefined;
  'vim-ehr-login-close': undefined;
  'vim-ehr-login-access-token': { token: string } | { error: any };
}

interface Message<T extends keyof MessagePayloadMap> {
  event: T;
  payload?: MessagePayloadMap[T];
}

export function notifyOpenerTab(message: Message<any>) {
  window.postMessage({ type: 'sendToOpenerTab', payload: message }, '*');
}
