import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { isEmpty, lowerFirst, trim } from 'lodash-es';
import Papa from 'papaparse';
import React, { ChangeEvent, useState } from 'react';
import { useApi } from '../../../api/use-api';
import { ImportUsersMutation } from '../../../api/users';
import { tableIcons } from '../../../assets/material-table-icons';
import { emailRegex } from '../../../common/regex';
import { MutationImportUsersArgs, User } from '../../../generated/graphql';
import { store } from '../../../stores/store';
import { messages } from '../../users/messages';
import { PreferredProvidersModalProps, NewUserInput, UserError } from '../types';
import { useAuth0Wrapper } from '../../../useAuth0Wrapper';

const USERS_PER_FILE_LIMIT = 200;

export const CsvUploadModal = ({ isOpen, onClose, organization }: PreferredProvidersModalProps) => {
  const sortByIndex = (a: UserError, b: UserError) => a.index - b.index;
  const [importUsers] = useApi<MutationImportUsersArgs, User>(ImportUsersMutation);
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileParsed, setIsFileParsed] = useState(false);
  const [isImportSent, setIsImportSent] = useState(false);
  const [errors, setErrors] = useState<UserError[]>([]);
  const { user: auth0User } = useAuth0Wrapper();

  const requiredHeaders = [
    'email',
    'firstName',
    'lastName',
    'npi',
    'tin',
    'linkedEhrUser',
    'products',
  ];

  const optionalHeaders = [
    'title',
    'tag',
    'organizationAdmin',
    'phoneNumber',
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'zip',
  ];
  const validHeaders = new Set([...requiredHeaders, ...optionalHeaders]);

  const validateHeaders = (headers: string[]): boolean => {
    const allHeadersAreValid = headers.every((header) => validHeaders.has(header));
    if (!allHeadersAreValid) return false;

    const allRequiredArePresent = requiredHeaders.every((required) => headers.includes(required));
    return allRequiredArePresent;
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const formatHeader = (header: string): string => lowerFirst(header).replaceAll(' ', '');

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const formatField = (field: string): string[] | string | null | boolean => {
    if (!field) return null;

    // Convert to array
    if (field.startsWith('[')) return trim(field, '[]').replaceAll(' ', '').split(',');
    // Convert to boolean
    if (field.toLowerCase() === 'true') return true;
    if (field.toLowerCase() === 'false') return false;

    return field;
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const [uploadedFile] = event.target.files!;

    if (uploadedFile) setCsvFile(uploadedFile);
  };

  const parseFile = () => {
    if (!csvFile) throw new Error('No csvFile in parsing function');
    Papa.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      transformHeader: formatHeader,
      transform: formatField,
      complete: parseResults,
      error: (error) => {
        store.showSnackbar(`Failed to parse csv file: ${error.message}`);
      },
    });
  };

  const importNewUsers = async (users) => {
    if (!usersAreValid(users)) {
      return;
    }
    const importUsersInput = buildImportUsersInput(users);
    await importUsers({
      input: {
        importedUsers: importUsersInput,
        organizationId: organization.id!,
        fileName: csvFile!.name,
        requestingUser: auth0User?.email,
      },
    });

    setIsImportSent(true);
  };

  const usersAreValid = (users) => {
    const invalidUsers = users.filter((newUser, userIndex) => !isValidUser(newUser, userIndex));
    if (!isEmpty(invalidUsers)) {
      return false;
    }
    return true;
  };

  const buildImportUsersInput = (users) => {
    return users.map((newUser) => buildNewUserInput(newUser));
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const buildNewUserInput = (newUser: NewUserInput) => {
    const {
      linkedEhrUser,
      organizationAdmin,
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      ...newUserData
    } = newUser;
    return {
      ...newUserData,
      tag: newUserData.tag?.replaceAll(' ', ''),
      isOrgAdmin: organizationAdmin,
      address: { addressLine1, addressLine2, city, state, zip },
      linkedEhrUser: linkedEhrUser.toLowerCase(),
    };
  };

  const emptyFileHandling = () => {
    setCsvFile(null);
    store.showSnackbar('File is empty. Please see the documentation.');
  };

  const parseResults = async (results) => {
    if (results.errors.length > 0) {
      results.errors[0].code === 'UndetectableDelimiter'
        ? emptyFileHandling()
        : store.showSnackbar(`CSV parsing finished with errors: ${results.errors}`);
    } else if (results.data.length === 0) {
      emptyFileHandling();
    } else if (validateHeaders(results.meta.fields)) {
      if (results.data.length > USERS_PER_FILE_LIMIT) {
        return store.showSnackbar(
          `The requested file has ${results.data.length} users. Please note the limit is ${USERS_PER_FILE_LIMIT} per file.`,
        );
      }
      setIsLoading(true);
      try {
        await importNewUsers(results.data);
      } catch {
        store.showSnackbar(messages.createError);
      }
      setIsLoading(false);
      setIsFileParsed(true);
    } else {
      store.showSnackbar('CSV format is invalid. Please see the documentation.');
    }
  };

  const validateUserProducts = (user: NewUserInput): boolean => {
    return (
      user.products === null ||
      (Array.isArray(user.products) &&
        user.products.every((product) =>
          (organization.products || []).find((orgProduct) => orgProduct.name === product),
        ))
    );
  };

  const isValidUser = (user: NewUserInput, userIndex: number): boolean => {
    const userErrors = getUserErrors(user);

    if (userErrors.length) {
      addErrors({ index: userIndex, messages: userErrors, user });
      return false;
    }

    return true;
  };

  const getUserErrors = (user: NewUserInput): string[] => {
    const userErrors: string[] = [];

    if (user.organizationAdmin && !user.email) {
      userErrors.push('Organization admin must be created with a valid Email');
    }
    if (user.email && !emailRegex.test(user.email)) userErrors.push('Email is invalid');
    if (!user.linkedEhrUser) userErrors.push('Must have a valid EHR user');
    if (!user.firstName) userErrors.push('Must have a valid first name');
    if (!user.lastName) userErrors.push('Must have valid last name');
    if (!validateUserProducts(user))
      userErrors.push("User's products must be valid and enabled for the organization or be blank");

    return userErrors;
  };

  const handleOnClose = () => {
    resetAllFields();
    onClose();
  };

  const resetAllFields = () => {
    setCsvFile(null);
    setIsFileParsed(false);
    setIsImportSent(false);
    setErrors([]);
  };

  const addErrors = (newError: UserError) => {
    setErrors((prevErrors) => [...prevErrors, newError]);
  };

  return (
    <Dialog onClose={handleOnClose} open={isOpen}>
      <Box display="flex" justifyContent="space-between" alignItems="center" paddingRight="1rem">
        <DialogTitle>{isFileParsed ? 'Import Users' : 'Load users from CSV'}</DialogTitle>
        <IconButton onClick={handleOnClose}>
          <tableIcons.Close />
        </IconButton>
      </Box>
      <DialogContent dividers>
        {!isFileParsed && <DialogContentText>(Up to 200 users per file)</DialogContentText>}
        {!isFileParsed && (
          <>
            {!csvFile ? (
              <Box style={{ textAlign: 'center' }}>
                <label htmlFor="csv-file">
                  <input
                    type="file"
                    id="csv-file"
                    accept=".csv"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    startIcon={<tableIcons.Backup />}
                  >
                    Select CSV File
                    {isLoading && <CircularProgress size={24} style={{ position: 'absolute' }} />}
                  </Button>
                </label>
              </Box>
            ) : (
              <Button
                onClick={parseFile}
                color="primary"
                variant="contained"
                component="span"
                startIcon={<tableIcons.Check />}
                disabled={isLoading}
              >
                Click to upload {csvFile.name}
                {isLoading && <CircularProgress size={24} style={{ position: 'absolute' }} />}
              </Button>
            )}
          </>
        )}

        {isImportSent && (
          <DialogContentText>
            <span className="summary-title">
              Your import request started. You will receive the full report through your email when
              it is done.
            </span>
          </DialogContentText>
        )}

        {isFileParsed && errors.length > 0 && (
          <>
            <DialogContentText>
              <span className="summary-title">Failed to create: </span>
              <span>{errors.length}</span>
            </DialogContentText>
            <DialogContentText>
              <span className="summary-title">Errors: </span>
              <ul>
                {errors.sort(sortByIndex).map((error) => (
                  <li key={error.index}>
                    User {error.index + 1} [{error.user.email}]:
                    <ul>
                      {error.messages.map((x) => (
                        <li key={x}>{x}</li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </DialogContentText>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
