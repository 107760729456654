import gql from 'graphql-tag';

export const getLoginMethodQuery = gql`
  query GetLoginMethod($input: OrganizationDataOnDevice!) {
    getDeviceLoginMethod(input: $input) {
      type
      metadata {
        redirectUrl
      }
    }
  }
`;

export const isEhrSupportSeamlessQuery = gql`
  query isEhrSupportSeamless($input: String!) {
    isEhrSupportSeamless(input: $input)
  }
`;

export const isSeamlessAvailableQuery = gql`
  query isSeamlessAvailable($input: IsSeamlessAvailableRequest!) {
    isSeamlessAvailable(input: $input) {
      isReachable
      isSslValid
    }
  }
`;
