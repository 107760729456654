import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

interface SeamlessSuggestionDialogProps {
  open: boolean;
  organizationName: string;
  onSave: (changeToSeamless: boolean) => void;
  onCancel: () => void;
}

export const SeamlessSuggestionDialog = ({
  open,
  organizationName,
  onSave,
  onCancel,
}: SeamlessSuggestionDialogProps) => {
  return (
    <Dialog open={open}>
      <DialogTitle className="dialog-message-title">
        <span>Save Organization</span>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          We see the organization supports seamless login.
          <br />
          Should {organizationName} be saved with seamless login as the identity provider?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSave(false)} variant="contained">
          Save as is
        </Button>
        <Button onClick={() => onSave(true)} variant="contained" color="primary">
          Save as Seamless
        </Button>
      </DialogActions>
    </Dialog>
  );
};
