import React, { useCallback, useEffect, useRef, useState } from 'react';
import { notifyOpenerTab } from '../messaging';
import { SuccessfulConnectionInfo } from './successful-connection-info';
import { FailedConnectionInfo } from './failed-connection-info';
import './sso-login.less';
import { parseJwt } from '../../common/jwt';

const accessTokenResponse = (accessToken: string) =>
  notifyOpenerTab({ event: 'vim-ehr-login-access-token', payload: { token: accessToken } });

const getAccessTokenFromUrl = (): string | null => {
  const urlParams = new URLSearchParams(window.location.hash.slice(1));
  return urlParams.get('access_token');
};

const getStateFromUrl = (): Record<any, any> | null => {
  const urlParams = new URLSearchParams(window.location.hash.slice(1));
  const stateAsString = urlParams.get('state');
  if (stateAsString) {
    try {
      return JSON.parse(atob(stateAsString));
    } catch {
      try {
        return JSON.parse(stateAsString);
      } catch {
        /* empty */
      }
    }
  }
  return null;
};

const errorResponse = () =>
  notifyOpenerTab({
    event: 'vim-ehr-login-access-token',
    payload: {
      error: `Couldn't extract access token from url params. url: ${window.location.href}`,
    },
  });

const extractSuccessfulConnectionInfo = (token: string) => {
  const jsonData = parseJwt(token);
  const userEmail = jsonData?.['https://getvim.com/email'];
  return { userEmail };
};

const extractErrorInfo = () => {
  const urlParams = new URLSearchParams(window.location.hash.slice(1));
  const errorName = urlParams.get('error');
  const errorDescription = urlParams.get('error_description');

  return { errorName, errorDescription };
};

const vimConnectIsContentScriptReadyRequest = 'vim-connect-is-content-script-ready-request';
const vimConnectIsContentScriptReadyResponse = 'vim-connect-is-content-script-ready-response';

export const SSOLogin = () => {
  const [isSuccessfulConnection, setIsSuccessfulConnection] = useState<boolean>(false);
  const [connectionInfo, setConnectionInfo] = useState<any>();
  const accessTokenResultRef = useRef<{ accessToken?: string | undefined; isError?: boolean }>({});

  const callback = useCallback(async ({ data }) => {
    if (data?.type === vimConnectIsContentScriptReadyResponse) {
      const { accessToken, isError } = accessTokenResultRef.current;
      if (accessToken) accessTokenResponse(accessToken);
      else if (isError) errorResponse();
    }
  }, []);

  useEffect(() => {
    const payload = { type: vimConnectIsContentScriptReadyRequest };

    window.addEventListener('message', callback);
    window.postMessage(payload);

    const interval = setInterval(() => window.postMessage(payload), 1000);

    setTimeout(() => {
      clearInterval(interval);

      /* Try send the access token even if we didn't get response from the extension, as the extension should always be ready.
        The extension relevant listeners should be ready before this page loads, as it runs in header scripts.
        Also using ref for access token as the state becomes stale when the timeout trigger */
      const { accessToken, isError } = accessTokenResultRef.current;
      if (accessToken) accessTokenResponse(accessToken);
      else if (isError) errorResponse();
    }, 5000);
  }, [callback]);

  useEffect(() => {
    if (!isSuccessfulConnection) {
      return;
    }

    const isWebview = Boolean((chrome as any).webview);
    if (!isWebview) {
      return;
    }

    /**
     * Webview uses a MessageChannel using internal API instead of postmessages
     * and needs to close itself - for more info see the implementation on - workflow-integration\src\webview2\runtime\sendMessage\onSendMessage\chrome-create-popup.ts;
     */
    try {
      const closeWindowFunction = (chrome as any).webview.hostObjects.sync.vimProxy
        .CloseCurrentWindow;
      const postBroadcastFunction = (chrome as any).webview.hostObjects.sync.vimProxy.PostBroadcast; // ("Hello, World!", "example-channel")

      const { accessToken } = accessTokenResultRef.current;

      postBroadcastFunction(accessToken, 'vim-access-token');

      closeWindowFunction();
    } catch {
      //  ignore
    }
  }, [isSuccessfulConnection]);

  useEffect(() => {
    const accessToken = getAccessTokenFromUrl();
    const state = getStateFromUrl();

    if (accessToken) {
      accessTokenResultRef.current = { accessToken };
      setIsSuccessfulConnection(true);
      setConnectionInfo(extractSuccessfulConnectionInfo(accessToken));
    } else {
      accessTokenResultRef.current = { isError: true };
      setIsSuccessfulConnection(false);
      setConnectionInfo(extractErrorInfo());
    }

    if (state?.redirectTo) {
      window.location.href = `${state.redirectTo}#access_token=${accessToken}`;
    }
  }, []);

  return (
    <div>
      {isSuccessfulConnection ? (
        <SuccessfulConnectionInfo userEmail={(connectionInfo?.userEmail ?? '') as string} />
      ) : (
        <FailedConnectionInfo
          errorMessage={(connectionInfo?.errorDescription ?? '') as string}
          errorName={(connectionInfo?.errorName ?? '') as string}
        />
      )}
    </div>
  );
};
