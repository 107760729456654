export const prettyEhrName = (ehrName) =>
  ({
    ecw: 'eCW',
    athena: 'Athena',
    'practice-fusion': 'Practice Fusion',
    allscripts: 'Allscripts',
    'office-ally': 'Office Ally',
    'mock-ehr': 'Mock EHR',
    'dr-chrono': 'DrChrono',
    kareo: 'Kareo',
    teladoc: 'Teladoc',
    'teladoc-salesforce': 'Teladoc-Salesforce',
  }[ehrName] || ehrName);
