import React from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PersonIcon from '@material-ui/icons/Person';
import DomainIcon from '@material-ui/icons/Domain';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import { Category } from './types';

export const defaultCategories: Category[] = [
  {
    id: 'Management',
    children: [
      {
        id: 'Organizations',
        icon: <DnsRoundedIcon color="primary" />,
        path: '/organizations',
        showChip: false,
      },
      {
        id: 'Users',
        icon: <PersonIcon color="primary" />,
        path: '/users',
        showChip: false,
      },
      {
        id: 'Vim Admins',
        icon: <SupervisorAccountIcon color="primary" />,
        path: '/admins',
        showChip: false,
      },
      {
        id: 'Organization Admins',
        icon: <DomainIcon color="primary" />,
        path: '/organization-admins',
        showChip: false,
      },
      {
        id: 'Audit',
        icon: <AssignmentIcon color="primary" />,
        path: '/audit',
        showChip: false,
      },
    ],
  },
];
