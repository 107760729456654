import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  TextFieldProps,
  withStyles,
  WithStyles,
  DialogContentText,
} from '@material-ui/core';
import React, { useEffect, useState, useMemo } from 'react';
import { styles } from '../../users/style';
import { NewProviderInput, ProviderLocationInput } from '../types';

interface ProviderDetailsDialogProps extends WithStyles<typeof styles> {
  open: boolean;
  initialProvider?: NewProviderInput;
  onClose: () => void;
  onSubmit: (newData, initialProvider, isNewProvider: boolean) => void;
  isSubmitButtonDisabled: boolean;
}
export const ProviderDetailsDialog = withStyles(styles)(
  ({
    open,
    initialProvider,
    onClose,
    onSubmit,
    classes,
    isSubmitButtonDisabled,
  }: ProviderDetailsDialogProps) => {
    const [provider, setProvider] = useState<NewProviderInput | undefined>(initialProvider);
    const [errors, setErrors] = useState<string[]>([]);
    const isNewProvider = useMemo<boolean>(() => !initialProvider, [initialProvider]);

    useEffect(() => {
      if (open) {
        setProvider(initialProvider);
      }
    }, [open]);

    const resetAllfields = () => {
      setErrors([]);
    };

    const handleClose = () => {
      resetAllfields();
      onClose();
    };

    const handleSubmit = (newData) => {
      const errors: string[] = [];
      if (!/^\d+$/.test(newData.npi) || newData.npi.length !== 10) {
        errors.push('Invalid NPI format.');
      }

      if (
        !(!newData?.city && !newData?.state && !newData?.zip && !newData?.addressLine1) && // Not all empty
        (!newData?.city || !newData?.state || !newData?.zip || !newData?.addressLine1) // One or more empty
      ) {
        errors.push(
          'Some locations fields are empty. Either fill them all or leave them all empty.',
        );
      }
      setErrors(() => [...errors]);
      if (errors.length > 0) return;

      const { addressLine1, city, state, zip, ...rest } = newData;
      const input = {
        ...rest,
        location: addressLine1
          ? {
              addressLine1,
              city,
              state,
              zip,
            }
          : undefined,
      };
      onSubmit(input, initialProvider, isNewProvider);
    };

    const genericTextfield = (
      field: keyof NewProviderInput | keyof ProviderLocationInput,
      props: TextFieldProps,
      convertToValue: (value) => any = (value) => value,
    ) => {
      return (
        <TextField
          value={provider?.[field] ?? ''}
          onChange={(event) => {
            const value = convertToValue(event.target?.value);
            setProvider({ ...provider, [field]: value } as NewProviderInput);
          }}
          {...props}
        />
      );
    };
    return (
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>{`${isNewProvider ? 'Create' : 'Update'} Provider Form`}</DialogTitle>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit(provider);
          }}
          id="provider-details-form"
          className={`${classes.gridRoot} ${classes.userFormRoot}`}
        >
          <div className={`${classes.gridRoot} ${classes.threeColumns}`}>
            {genericTextfield('npi', {
              label: 'NPI',
              autoFocus: true,
              required: true,
            })}
            {genericTextfield('firstName', { label: 'First Name' })}
            {genericTextfield('lastName', { label: 'Last Name' })}
            {genericTextfield('addressLine1', { label: 'Address Line 1' })}
            {genericTextfield('state', { label: 'State' })}
            {genericTextfield('zip', { label: 'Zip' })}
            {genericTextfield('city', { label: 'City' })}
          </div>
          {errors.length > 0 && (
            <>
              <DialogContentText>
                <span className="summary-title">Errors: </span>
                <ul>
                  {errors.map((error, index) => (
                    <li>{error}</li>
                  ))}
                </ul>
              </DialogContentText>
            </>
          )}
        </form>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            form="provider-details-form"
            color="primary"
            autoFocus
            disabled={isSubmitButtonDisabled}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
