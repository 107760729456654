import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  TextField,
  withStyles,
  Button,
  WithStyles,
} from '@material-ui/core';
import { styles } from './style';
import { useApi } from '../../../api/use-api';
import { MutationUpdateUserEmailArgs, SuccessStatus } from '../../../generated/graphql';
import { updateUserEmailMutation } from '../../../api/users';
import { store } from '../../../stores/store';
import { messages } from '../messages';

interface EmailDialogProps extends WithStyles<typeof styles> {
  userId?: string | null;
  organizationId?: number | null;
  email?: string | null;
  linkedEhrUser?: string | null;
  isOrgAdmin?: boolean | null;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSubmit: (newEmail: string) => void;
}

const LOWER_CASE_TEXT = 'Only lowercase characters';
const INPUT_ERROR = 'Please enter a valid email address';
const LINKED_EHR_USER_ERROR = 'The user must have an email or EHR username';
const ORG_ADMIN_ERROR = 'Organization admin must have an email';

export const EmailDialog = withStyles(styles)(
  ({
    userId,
    organizationId,
    email,
    linkedEhrUser,
    isOrgAdmin,
    onSubmit,
    isOpen,
    setIsOpen,
    classes,
  }: EmailDialogProps) => {
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
    const [isInputError, setIsInputError] = useState(false);
    const [isLinkedEhrError, setIsLinkedEhrError] = useState(false);
    const [isOrgAdminError, setIsOrgAdminError] = useState(false);
    const [newEmail, setEmail] = useState<string | null | undefined>(email);
    const [updateUserEmailApi, sendingRequest] = useApi<MutationUpdateUserEmailArgs, SuccessStatus>(
      updateUserEmailMutation,
    );

    useEffect(() => {
      setEmail(email);
    }, [email]);

    const getHelperText = (): string => {
      if (isInputError) return INPUT_ERROR;
      if (isLinkedEhrError) return LINKED_EHR_USER_ERROR;
      if (isOrgAdminError) return ORG_ADMIN_ERROR;
      return LOWER_CASE_TEXT;
    };

    useEffect(() => {
      setIsSubmitButtonDisabled(
        // send request
        sendingRequest ||
          // no valid input
          (!linkedEhrUser && !newEmail) ||
          // no real changes
          newEmail === email ||
          // admin user must have email
          (!!isOrgAdmin && !newEmail),
      );
    }, [newEmail, email, linkedEhrUser, sendingRequest, isOrgAdmin]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onEmailSubmit();
    };

    const onEmailSubmit = async () => {
      try {
        await updateUserEmail();
        onSubmit(newEmail ?? '');
        setIsOpen(false);
        store.showSnackbar(messages.updateEmailSuccess);
      } catch (error: any) {
        const errorDisplayedMessage = createErrorMessage(error);
        store.showSnackbar(errorDisplayedMessage);
      }
    };

    const updateUserEmail = () => {
      return updateUserEmailApi({
        input: {
          externalId: userId!,
          email: newEmail!.trim(),
        },
      });
    };

    const createErrorMessage = (error: any) => {
      const { message, name, extensions } = error;
      const errorName = name || extensions?.error?.name;
      const shouldUseDefaultErrorMessage = !(
        (errorName === 'GraphQLError' || !errorName) &&
        message
      );
      return shouldUseDefaultErrorMessage ? messages.updateEmailError : message;
    };

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      const value = !event.target.value ? '' : event.target.value.toLowerCase();
      setIsLinkedEhrError(!value && !linkedEhrUser);
      setIsOrgAdminError(!value && !!isOrgAdmin);
      setEmail(value);
    };

    const handleOnClick = () => {
      setIsOpen(false);
      setIsInputError(false);
      setIsLinkedEhrError(false);
      setIsOrgAdminError(false);
      setEmail(email);
    };

    const handleOnInput = (event: ChangeEvent<HTMLInputElement>) => {
      setIsInputError(event.target.validity.typeMismatch);
    };

    return (
      <Dialog open={isOpen}>
        <form
          onSubmit={(event) => {
            handleSubmit(event);
          }}
          id="email-edit-form"
          className={`${classes.dialogForm}`}
        >
          <div className={`${classes.inputsRow}`}>
            <TextField
              helperText={getHelperText()}
              fullWidth
              error={isInputError || isLinkedEhrError || isOrgAdminError}
              label="Email"
              type="email"
              className={`${classes.editInput}`}
              value={newEmail || ''}
              onChange={handleOnChange}
              onInput={handleOnInput}
            />
          </div>
        </form>
        <DialogActions>
          <Button onClick={handleOnClick} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={isSubmitButtonDisabled}
            type="submit"
            form="email-edit-form"
            color="primary"
            variant="contained"
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
