import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { SsoDebugProperties } from './types';
import { tableIcons } from '../../assets/material-table-icons';
import { store } from '../../stores/store';

export const SsoDebug = ({ redirectUrl }: SsoDebugProperties) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAddUsersClick = () => {
    setIsModalOpen(true);
  };

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(redirectUrl);
    store.showSnackbar('Copied to clipboard!');
  };

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        component="span"
        onClick={handleAddUsersClick}
        style={{ marginLeft: '10px' }}
      >
        Debug SSO connection
      </Button>

      <Dialog onClose={handleModalClose} open={isModalOpen}>
        <Box display="flex" justifyContent="space-between" alignItems="center" paddingRight="1rem">
          <DialogTitle>SSO debug URL</DialogTitle>

          <IconButton onClick={handleModalClose}>
            <tableIcons.Close />
          </IconButton>
        </Box>
        <DialogContent dividers>
          {' '}
          <DialogContentText style={{ fontWeight: 'bold' }}>
            Copy following URL and paste in customer's browser:
          </DialogContentText>
          <DialogContentText>{redirectUrl} </DialogContentText>
          <Button type="button" onClick={copyToClipboard} variant="outlined">
            Copy to clipboard
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};
