import { GraphQLError } from 'graphql';

interface ErrorResponseHandlers {
  loginWithRedirect: () => Promise<void>;
}

enum ResponseErrorTypes {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

export const throwErrorsFromResponse = async (jsonResponse): Promise<void> => {
  if (jsonResponse.errors) {
    const [error] = jsonResponse.errors;
    throw error;
  }
};

export const handleResponseError = async (
  error: GraphQLError,
  handlerFunctions: ErrorResponseHandlers,
): Promise<void> => {
  switch (error?.extensions?.code) {
    case ResponseErrorTypes.UNAUTHENTICATED:
      await handlerFunctions.loginWithRedirect();
      break;
    default:
      throw error;
  }
};
