import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { isEmpty } from 'lodash-es';
import { ExtendedUser } from '../../../common/user/types';

interface DeleteDialogProps {
  open: boolean;
  user: Partial<ExtendedUser>;
  onClose: () => void;
  onDelete: (userId: ExtendedUser) => void;
}

const getUsername = ({ email, linkedEhrUser }: Partial<ExtendedUser>): string =>
  email || linkedEhrUser || '';

export const DeleteDialog = ({ open, user, onDelete, onClose }: DeleteDialogProps) => {
  const username = getUsername(user);

  const handleDelete = () => {
    if (user.id) {
      onDelete(user);
    }
    onClose();
  };

  return (
    <Dialog open={open && !isEmpty(user.id)} onClose={onClose}>
      <DialogTitle>Delete user</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete <b>{username}</b>? This cannot be undone!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleDelete} variant="contained" color="secondary">
          Yes, delete it
        </Button>
      </DialogActions>
    </Dialog>
  );
};
