import gql from 'graphql-tag';

export const partialUserFragment = gql`
  fragment PartialUserFragment on User {
    createdAt
    activatedAt
    id
    username
    email
    phoneNumber
    blocked
    connection
    isOrgAdmin
    ehrUser {
      organization {
        id
        name
        accountId
      }
      products {
        name
      }
      npi
      tin
      title
      titles
      roles
      address {
        addressLine1
        addressLine2
        city
        state
        zip
        fullAddress
      }
      firstName
      lastName
      linkedEhrUser
      tag
    }
  }
`;

export const partialUserOmitIsOrgAdminFragment = gql`
  fragment PartialUserOmitIsOrgAdminFragment on User {
    createdAt
    activatedAt
    id
    username
    email
    phoneNumber
    blocked
    connection
    accountId
    ehrUser {
      organization {
        id
        name
      }
      products {
        name
      }
      npi
      tin
      title
      titles
      roles
      address {
        addressLine1
        addressLine2
        city
        state
        zip
        fullAddress
      }
      firstName
      lastName
      linkedEhrUser
      tag
    }
  }
`;

export const userFragment = gql`
  fragment UserFragment on User {
    isOrgAdmin
    ...PartialUserFragment
  }

  ${partialUserFragment}
`;

export const userOmitIsOrgAdminFragment = gql`
  fragment UserOmitIsOrgAdminFragment on User {
    ...PartialUserOmitIsOrgAdminFragment
  }

  ${partialUserOmitIsOrgAdminFragment}
`;

export const getUsersPaginationOmitIsOrgAdminQuery = gql`
  query getUsersPagination($input: GetUsersPaginationInput!) {
    getUsersPagination(input: $input) {
      count
      users {
        ...UserOmitIsOrgAdminFragment
      }
    }
  }
  ${userOmitIsOrgAdminFragment}
`;

export const auth0UsersQuery = gql`
  query auth0Users($input: Auth0UsersInput!) {
    auth0Users(input: $input) {
      users {
        user_id
        name
        email
        connection
        created_at
        logins_count
      }
    }
  }
`;

export const getUserByIdQuery = gql`
  query getUserById($id: ID!) {
    getUserById(id: $id) {
      ...UserFragment
    }
  }
  ${userFragment}
`;

export const createUserMutation = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ...UserFragment
    }
  }
  ${userFragment}
`;

export const ImportUsersMutation = gql`
  mutation importUsers($input: ImportUsersInput!) {
    importUsers(input: $input) {
      success
    }
  }
`;

export const updateUserMutation = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...UserFragment
    }
  }
  ${userFragment}
`;

export const createConsoleUserMutation = gql`
  mutation CreateConsoleUser($input: CreateConsoleUserInput!) {
    createConsoleUser(input: $input) {
      success
    }
  }
`;

export const deleteConsoleUserMutation = gql`
  mutation DeleteConsoleUser($input: DeleteConsoleUserInput!) {
    deleteConsoleUser(input: $input) {
      success
    }
  }
`;

export const updateUserLinkedEhrUserMutation = gql`
  mutation updateUserLinkedEhrUser($input: UpdateUserLinkedEhrUser!) {
    updateUserLinkedEhrUser(input: $input) {
      success
    }
  }
`;

export const updateUserEmailMutation = gql`
  mutation updateUserEmail($input: UpdateUserEmailInput!) {
    updateUserEmail(input: $input) {
      success
    }
  }
`;

export const deleteUserMutation = gql`
  mutation deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input)
  }
`;

export const deleteAuth0UsersMutation = gql`
  mutation deleteAuth0Users($input: DeleteAuth0UsersInput!) {
    deleteAuth0Users(input: $input) {
      userIds
    }
  }
`;

export const seamlessVerificationMutation = gql`
  mutation seamlessVerification($input: SeamlessVerificationInput!) {
    seamlessVerification(input: $input) {
      success
    }
  }
`;

export const activateUserMutation = gql`
  mutation activateUser($token: String!) {
    activateUser(token: $token)
  }
`;

export const resendActivationMailMutation = gql`
  mutation resendActivationMail($input: ResendActivationMailInput!) {
    resendActivationMail(input: $input)
  }
`;

export const sendPasswordResetEmailMutation = gql`
  mutation SendPasswordResetEmail($input: SendPasswordResetEmailInput!) {
    sendPasswordResetEmail(input: $input)
  }
`;
