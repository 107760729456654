import { useCallback } from 'react';
import { useApi } from '../../api/use-api';
import { activateUserMutation } from '../../api/users';
import { httpClient } from '../../services';

export interface ActivateUserResult {
  activateUser(token: string, shouldUseRestApiFF): void;
}

export const useActivateUser = (): ActivateUserResult => {
  const [setActivateUser] = useApi<{ token: string }, { status: string }>(activateUserMutation);

  const activateUser = useCallback(
    (token: string, shouldUseRestAPIFF: boolean) => {
      if (shouldUseRestAPIFF) {
        return httpClient.post('/v1/users/activation', { token });
      }
      return setActivateUser({ token }, undefined, true);
    },
    [setActivateUser],
  );

  return { activateUser };
};
