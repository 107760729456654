import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  withStyles,
  WithStyles,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { startCase, isEmpty } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Loader,
  PhoneNumberInput,
  MultiSelectWithOther as MultiSelectWithOtherInput,
} from '@getvim/atomic-ui';
import { diff } from 'deep-object-diff';
import { ROLES } from '@getvim/platform-consts';
import { ExtendedUser } from '../../common/user/types';
import {
  GetUserByIdResult,
  Organization,
  QueryGetUserByIdArgs,
  Tags,
} from '../../generated/graphql';
import { styles } from './style';
import { AddressInput } from '../address/address-input';
import { productDisplaysNames } from '../../common/app-product-map';
import { MultipleLabelsTextField as MultiLabelField } from '../common/multi-label-txt-field';
import { transformUser, transformUserToSubmit } from '../../common/user/user-logic';
import { LinkedEhrUserInput } from '../linkedEhrUser/linked-ehr-user-input';
import { getUserByIdQuery } from '../../api/users';
import { useApi } from '../../api/use-api';
import { EmailInput } from './email/email-input';
import { Team, useFeatureFlag } from '@getvim/feature-flags-react';

const EMPTY_USER: ExtendedUser = {};

interface UserDetailsDialogProps extends WithStyles<typeof styles> {
  open: boolean;
  userId?: string | null;
  organizations: Organization[];
  onClose: () => void;
  onSubmit: (newData, initialUser, isNewUser: boolean) => void;
  isSubmitButtonDisabled: boolean;
}

const UserDetailsDialog = withStyles(styles)(
  ({
    userId,
    organizations,
    onClose,
    onSubmit,
    open,
    classes,
    isSubmitButtonDisabled,
  }: UserDetailsDialogProps) => {
    const [user, setUser] = useState<ExtendedUser>(EMPTY_USER);
    const [initialUser, setInitialUser] = useState<ExtendedUser>(EMPTY_USER);
    const [disableModalOnClose, setDisableModalOnClose] = useState(true);

    const [getUserById, getUserByIdLoading] = useApi<QueryGetUserByIdArgs, GetUserByIdResult>(
      getUserByIdQuery,
    );
    const [isUserChanged, setIsUserChanged] = useState(false);

    useEffect(() => {
      const diffBetweenInitialUserAndCurrentUser = diff(initialUser, user);

      setIsUserChanged(Object.keys(diffBetweenInitialUserAndCurrentUser).length === 0);
    }, [user, initialUser]);

    useEffect(() => {
      const fetchUserById = async () => {
        if (open && !userId) {
          // new user modal

          setDisableModalOnClose(false);
        } else if (open && userId) {
          // update user modal

          const {
            data: { getUserById: result },
          } = await getUserById({ id: userId });

          setDisableModalOnClose(false);
          setUser(transformUser(result));
          setInitialUser(transformUser(result));
        } else {
          setDisableModalOnClose(true);
          setUser(EMPTY_USER);
        }
      };

      fetchUserById();
    }, [open, userId]);

    const isNewUser = () => !userId;

    const getDisableUpdateInputProps = () => ({
      disabled: !isNewUser(),
      disableUnderline: !isNewUser(),
    });

    const handleClose = () => {
      onClose();
      setUser({});
      setDisableModalOnClose(true);
    };

    const handleSubmit = (newData?: ExtendedUser) => {
      setDisableModalOnClose(true);

      const newUserData = transformUserToSubmit(newData);
      onSubmit(newUserData, initialUser, isNewUser());
    };

    const genericTextfield = (
      field: keyof ExtendedUser,
      props: TextFieldProps,
      convertToValue: (value) => any = (value) => value,
      helperText?: string,
    ) => {
      return (
        <TextField
          helperText={helperText}
          value={user?.[field] ?? ''}
          onChange={({ target }) => {
            const value = !target?.value ? null : convertToValue(target?.value);
            setUser({ ...user, [field]: value });
          }}
          {...props}
        />
      );
    };

    const getOrganization = useCallback(
      (orgId) => {
        if (!orgId) return;
        return organizations.find(({ id }) => orgId.toString() === id?.toString());
      },
      [organizations],
    );

    const createTagsItems = () => {
      return [
        ...Object.values(Tags).map((tag) => (
          <MenuItem key={tag} value={tag}>
            {startCase(tag)}
          </MenuItem>
        )),
        <MenuItem key="no-tag" value="">
          No tag
        </MenuItem>,
      ];
    };

    const orgProducts = useMemo(
      () => getOrganization(user?.organization?.id)?.products,
      [getOrganization, user],
    );

    const orgTag = useMemo(
      () => getOrganization(user?.organization?.id)?.tag,
      [getOrganization, user],
    );

    const orgProductsDisplayNames = useMemo(
      () =>
        orgProducts?.reduce(
          (acc, product) => ({ ...acc, [product.name]: product.displayName }),
          {},
        ),
      [orgProducts],
    );

    return (
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown={disableModalOnClose}
        disableBackdropClick={disableModalOnClose}
      >
        {getUserByIdLoading ? (
          <Loader />
        ) : (
          <>
            <DialogTitle>{`${isNewUser() ? 'Create' : 'Update'} User Form`}</DialogTitle>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit(user);
              }}
              id="user-details-form"
              className={`${classes.gridRoot} ${classes.userFormRoot}`}
            >
              <div className={`${classes.gridRoot} ${classes.threeColumns}`}>
                {!isNewUser() ? (
                  <EmailInput
                    onSubmit={(newEmail) => {
                      setUser({ ...user, email: newEmail });
                      setInitialUser({ ...user, email: newEmail });
                    }}
                    linkedEhrUser={user?.linkedEhrUser ?? ''}
                    userId={user?.id}
                    organizationId={user?.organization?.id}
                    email={user?.email ?? ''}
                    isOrgAdmin={user?.isOrgAdmin ?? false}
                  />
                ) : (
                  genericTextfield(
                    'email',
                    {
                      autoFocus: true,
                      label: 'Email Address',
                      type: 'email',
                      InputProps: getDisableUpdateInputProps(),
                    },
                    (value: string) => value.toLowerCase(),
                  )
                )}

                <span className={`${classes.userPhoneNumber}`}>
                  <PhoneNumberInput
                    onChange={(event) => {
                      if (event) {
                        const { number, countryDialingCode } = event;
                        const numberValue = !number
                          ? null
                          : (`${countryDialingCode}${number}` as string);
                        setUser({
                          ...user,
                          phoneNumber: numberValue,
                        });
                      }
                    }}
                    value={{ number: user?.phoneNumber || '', countryDialingCode: '' }}
                    label="User Phone Number"
                    className="userPhoneNumberInput"
                    placeholder="Phone Number"
                  />
                </span>

                <TextField
                  select
                  fullWidth
                  label="User Tag"
                  disabled={!!orgTag}
                  value={orgTag || user?.tag || ''}
                  onChange={({ target }) => {
                    const value = target.value === '' ? null : target.value;
                    setUser({ ...user, tag: value as Tags });
                  }}
                >
                  {createTagsItems()}
                </TextField>
              </div>
              <div className={`${classes.gridRoot} ${classes.threeColumns}`}>
                {genericTextfield('firstName', { label: 'First Name', required: true })}
                {genericTextfield('lastName', { label: 'Last Name', required: true })}
                {genericTextfield('title', { label: 'User custom field' })}
              </div>
              <div className={`${classes.gridRoot} ${classes.oneColumn}`}>
                {(isNewUser() || !isEmpty(user)) && (
                  <MultiSelectWithOtherInput
                    title="Roles"
                    onChange={(roles) => {
                      setUser({ ...user, titles: roles, roles });
                    }}
                    options={ROLES}
                    width="100%"
                    size="small"
                    defaultValues={user?.roles || user?.titles || undefined}
                    variant="standard"
                    style={{
                      '& .MuiFormLabel-root': { fontSize: '1.5rem' },
                      '& .MuiInput-root': { marginTop: '2.3rem' },
                      display: 'flex',
                      'align-items': 'flex-end',
                    }}
                    limitTags={3}
                  />
                )}
              </div>
              <div className={`${classes.gridRoot} ${classes.organizationProductsRow}`}>
                <Autocomplete
                  value={user?.organization || null}
                  disabled={!isNewUser()}
                  options={organizations}
                  getOptionLabel={({ name }) => name!}
                  renderInput={(params) => <TextField {...params} label="Organization" required />}
                  onChange={(_, value) =>
                    setUser({
                      ...user,
                      organization: getOrganization(value?.id),
                      products: [],
                      tag: getOrganization(value?.id)?.tag || user.tag,
                    })
                  }
                />

                <FormControl fullWidth>
                  <InputLabel id="products-label">Products</InputLabel>
                  <Select
                    disabled={!user?.organization}
                    labelId="products-label"
                    multiline
                    multiple
                    value={user?.products ?? []}
                    input={<Input />}
                    renderValue={(selected) => (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {(selected as string[]).map((value) => (
                          <Chip
                            key={value}
                            label={
                              orgProductsDisplayNames?.[value] ??
                              startCase(productDisplaysNames[value] || value)
                            }
                            style={{ margin: 2 }}
                          />
                        ))}
                      </div>
                    )}
                    onChange={(event) =>
                      setUser({ ...user, products: event.target.value as string[] })
                    }
                  >
                    {orgProducts?.map(({ name, displayName }) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                          checked={!!user?.products?.find((productName) => productName === name)}
                        />
                        {displayName ?? startCase(productDisplaysNames[name] || name)}
                      </MenuItem>
                    )) ?? []}
                  </Select>
                </FormControl>
              </div>
              <div className={`${classes.gridRoot} ${classes.threeColumns}`}>
                {genericTextfield('npi', {
                  label: 'National Provider ID',
                })}
                <MultiLabelField
                  value={user?.tin || []}
                  inputLabel="Tax ID Number"
                  onChange={(labels) => setUser({ ...user, tin: labels })}
                />
                <AddressInput
                  onSubmit={(newAddress) => setUser({ ...user, address: newAddress })}
                  initialAddress={user?.address}
                />
              </div>
              <div className={`${classes.gridRoot} ${classes.threeColumns}`}>
                {!isNewUser() ? (
                  <LinkedEhrUserInput
                    onSubmit={(newLinkedEhrUser) => {
                      setUser({ ...user, linkedEhrUser: newLinkedEhrUser });
                      setInitialUser({ ...user, linkedEhrUser: newLinkedEhrUser });
                    }}
                    initialLinkedEhrUser={user?.linkedEhrUser ?? ''}
                    userId={user?.id}
                    organizationId={user?.organization?.id}
                    email={user?.email}
                  />
                ) : (
                  genericTextfield(
                    'linkedEhrUser',
                    { label: 'Linked EHR User', InputProps: { ...getDisableUpdateInputProps() } },
                    (value: string) => value.toLowerCase(),
                    user?.id ? '' : 'Only lowercase characters',
                  )
                )}
                <FormControl className={`${classes.checkboxField}`}>
                  <FormControlLabel
                    label="Organization Admin"
                    control={
                      <Checkbox
                        checked={!!user?.isOrgAdmin}
                        disabled={!isNewUser() && !user?.email}
                        onChange={(event) =>
                          setUser({ ...user, isOrgAdmin: event.target?.checked })
                        }
                      />
                    }
                  />
                </FormControl>
              </div>
            </form>
            <DialogActions>
              <Button disabled={isSubmitButtonDisabled} onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <Button
                type="submit"
                form="user-details-form"
                variant="contained"
                color="primary"
                autoFocus
                disabled={isSubmitButtonDisabled || isUserChanged}
              >
                Submit
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  },
);

export { UserDetailsDialog };
