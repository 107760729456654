import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import { Omit } from '@material-ui/types';
import { useHistory, useLocation } from 'react-router-dom';
import { BaseProperties } from '@getvim/platform-types';
import AdbIcon from '@material-ui/icons/Adb';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { styles } from './style';
import vimLogo from '../../assets/img/vim-logo.png';
import { defaultCategories } from './config';
import { useAnalytics } from '../providers/analytics-provider';
import { useEnableInvestigatorPage } from '../../common/use-enable-investigator';
import { useAuth0Wrapper } from '../../useAuth0Wrapper';

export interface NavigatorProps extends Omit<DrawerProps, 'classes'>, WithStyles<typeof styles> {}

export const Navigator = withStyles(styles)((props: NavigatorProps) => {
  const { logout, user } = useAuth0Wrapper();

  const analyticsClient = useAnalytics();
  const history = useHistory();
  const location = useLocation();
  const enableInvestigatorPage = useEnableInvestigatorPage();

  const { classes, ...other } = props;

  const [categories, setCategories] = useState(defaultCategories);

  useEffect(() => {
    if (enableInvestigatorPage) {
      defaultCategories[1] = {
        id: 'Investigators',
        children: [
          {
            id: 'Investigator',
            icon: <AdbIcon color="primary" />,
            path: '/investigator',
            showChip: false,
          },
          {
            id: 'Seamless Verification',
            icon: <VerifiedUserIcon color="primary" />,
            path: '/seamless-verification',
            showChip: false,
          },
        ],
      };

      setCategories(defaultCategories);
    }
  }, [enableInvestigatorPage]);

  useEffect(() => {
    if (user?.sub && analyticsClient) {
      const baseProperties: Partial<BaseProperties> = {
        vim_user: {
          user_id: user.sub,
          email: user.email,
          organization_id: user.organization?.id,
          organization_name: user.organization?.name,
        },
      };
      analyticsClient.setBaseProperties(baseProperties);
    }
  }, [user, analyticsClient]);

  const onLogout = () => logout({ returnTo: window.location.origin });

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          style={{ justifyContent: 'space-between' }}
          className={clsx(classes.firebase, classes.item, classes.itemCategory)}
        >
          <Typography
            variant="h5"
            style={{ display: 'flex', alignItems: 'center', fontWeight: 'normal' }}
          >
            <img src={vimLogo} style={{ width: '3.5rem', marginRight: '1rem' }} alt="vim logo" />
            Console
          </Typography>
          <IconButton onClick={onLogout}>
            <ExitToAppTwoToneIcon color="primary" />
          </IconButton>
        </ListItem>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          <Typography variant="body2">
            Welcome, {user?.nickname} {user?.email}
          </Typography>
          <img src={user?.picture} style={{ width: '4rem', borderRadius: '50%' }} alt="user" />
        </ListItem>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText classes={{ primary: classes.categoryHeaderPrimary }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, path, showChip }) => {
              const active = location.pathname === path;

              return (
                <ListItem
                  key={childId}
                  button
                  className={clsx(classes.item, active && classes.itemActiveItem)}
                  onClick={() => history.push(path)}
                >
                  <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                  <ListItemText classes={{ primary: classes.itemPrimary }}>{childId}</ListItemText>
                  {showChip && (
                    <Chip
                      label="BETA"
                      size="small"
                      style={{ fontSize: '1rem', height: '1.5rem', color: '#000', fontWeight: 500 }}
                    />
                  )}
                </ListItem>
              );
            })}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
});
