import React, { useEffect, useState } from 'react';
import { Chip, TextField } from '@material-ui/core';
import { isEmpty } from 'lodash-es';
import { v4 as uuid } from 'uuid';

export const MultipleLabelsTextField = (props) => {
  const { value, onChange, inputLabel } = props;
  const [labels, setLabels] = useState<string[]>(value || []);
  const [currentTextFieldValue, setCurrentTextFieldValue] = useState<string>('');

  const onDelete = (label) => {
    const newLabels = labels.filter((_label) => _label !== label);

    setLabels(newLabels);
    onChange(newLabels);
  };

  const onBlur = (event) => {
    const label = (event.target as HTMLInputElement).value?.trim();
    if (isEmpty(label)) return;
    const newLabels = [...labels, label];

    setLabels(newLabels);
    setCurrentTextFieldValue('');
    onChange(newLabels);
  };

  useEffect(() => {
    if (value) setLabels(value);
  }, [props]);

  return (
    <div>
      <TextField
        label={inputLabel}
        value={currentTextFieldValue}
        onChange={(event) => setCurrentTextFieldValue(event.target.value)}
        onKeyPress={(event) => {
          let label = (event.target as HTMLInputElement).value;
          label = label.trim();
          if (![' ', 'Enter'].includes(event.key) || isEmpty(label)) return;
          const newLabels = [...labels, label];
          setLabels(newLabels);
          setCurrentTextFieldValue('');
          onChange(newLabels);
        }}
        onBlur={onBlur}
      />
      {labels?.map((label) => (
        <Chip
          key={uuid()}
          size="small"
          variant="outlined"
          label={label}
          onDelete={() => onDelete(label)}
          onClick={() => onDelete(label)}
        />
      ))}
    </div>
  );
};
