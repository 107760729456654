// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action } from 'mobx';

class Store {
  @observable snackbarVisible = false;

  @observable snackbarMessage = '';

  @action showSnackbar(message: string) {
    this.snackbarVisible = true;
    this.snackbarMessage = message;
  }
}

export const store = new Store();
