import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { AddChangesToUsersModal } from './AddChangesToUsersModal';
import { UpdateOrganizationUsersBtnProps } from '../types';

export const AddChangesToUsersBtn = ({
  organization,
  setProductsToAdd,
  setProductsToRemove,
  setTinUpdate,
}: UpdateOrganizationUsersBtnProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAddUsersClick = () => {
    setIsModalOpen(true);
  };

  return (
    <span style={{ marginLeft: '10px' }}>
      <Button color="primary" variant="contained" component="span" onClick={handleAddUsersClick}>
        Add changes to organization users
      </Button>

      <AddChangesToUsersModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        products={organization.products || []}
        setProductsToAdd={setProductsToAdd}
        setProductsToRemove={setProductsToRemove}
        setTinUpdate={setTinUpdate}
      />
    </span>
  );
};
