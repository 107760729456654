export const messages = {
  createSuccess: 'Created user successfully',
  createError: 'Failed to create user',
  updateSuccess: 'Updated user successfully',
  updateError: 'Failed to update user',
  updateLinkedEhrUserSuccess: 'Updated linkedEhrUser successfully',
  updateLinkedEhrUserError: 'Failed to update linkedEhrUser',
  updateEmailSuccess: 'Updated Email successfully',
  updateEmailError: 'Failed to update Email',
  deleteSuccess: 'Deleted user successfully',
  deleteError: 'Failed to delete user',
  sendActivationMailSuccess: 'Sent activation mail successfully',
  sendActivationMailError: 'Failed to send activation mail',
  rowMissingId: 'Table row missing an ID',
  searchError: 'Failed to search user, please try again',
};
