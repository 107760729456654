import React, { useState } from 'react';
import { IconButton, TextField, withStyles, WithStyles, InputAdornment } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { styles } from './style';
import { LinkedEhrUserDialog } from './linked-ehr-user-modal';

interface LinkedEhrUserInputProps extends WithStyles<typeof styles> {
  initialLinkedEhrUser?: string | null;
  userId?: string | null;
  organizationId?: number | null;
  email?: string | null;
  onSubmit: (newData) => void;
}

export const LinkedEhrUserInput = withStyles(styles)(
  ({
    initialLinkedEhrUser,
    userId,
    organizationId,
    email,
    onSubmit,
    classes,
  }: LinkedEhrUserInputProps) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <>
        <TextField
          fullWidth
          label="Linked Ehr User"
          value={initialLinkedEhrUser}
          className={`${classes.linkedEhrUserPreviewInput}`}
          InputProps={{
            disabled: true,
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Edit Linked Ehr User"
                  onClick={() => setIsOpen(true)}
                  onMouseDown={(event) => event.preventDefault()}
                >
                  <EditIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LinkedEhrUserDialog
          initialLinkedEhrUser={initialLinkedEhrUser}
          userId={userId}
          organizationId={organizationId}
          email={email}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onSubmit={onSubmit}
        />
      </>
    );
  },
);
