import { Team, useFeatureFlag } from '@getvim/feature-flags-react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { camelCase, isEmpty, trim } from 'lodash-es';
import Papa from 'papaparse';
import React, { ChangeEvent, useCallback, useState } from 'react';
import {
  bulkUpsertPreferredProvidersMutation,
  upsertPreferredProviderMutation,
} from '../../../api/providers';
import { useApi } from '../../../api/use-api';
import { tableIcons } from '../../../assets/material-table-icons';
import { Provider } from '../../../generated/graphql';
import { store } from '../../../stores/store';
import { messages } from '../messages';
import {
  NewProviderInput,
  PreferredProvidersModalProps,
  ProviderCSVRecord,
  ProviderError,
} from '../types';
import { validateData } from './validateCSV';

type BulkProviderInput = {
  location?: {
    addressLine1?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
} & Pick<Provider, 'address' | 'firstName' | 'lastName' | 'npi' | 'specialty' | 'tin'>;

const INVALID_FORMAT_MESSAGE = 'Invalid format file. Make sure to upload a CSV format';

export const ProviderCsvUploadModal = ({
  isOpen,
  onClose,
  organization,
  onFinish,
}: PreferredProvidersModalProps) => {
  const sortByIndex = (a: ProviderError, b: ProviderError) => a.index - b.index;
  const [createProvider] = useApi<any, Provider>(upsertPreferredProviderMutation);
  const [bulkInsertProviders] = useApi<
    { input: { orgId: string; providers: BulkProviderInput[] } },
    { newLocations: number; newProviders: number }
  >(bulkUpsertPreferredProvidersMutation);
  const [useBulkProviders] = useFeatureFlag({
    flagName: 'useBulkProvidersEndpoint',
    defaultValue: false,
    team: Team.OrderOptimization,
  });
  const [successCount, setSuccessCount] = useState(0);
  const [locationSuccessCount, setSuccessLocationCount] = useState(0);
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileParsed, setIsFileParsed] = useState(false);
  const [formatParsingError, setFormatParsingError] = useState<boolean>(false);
  const [errors, setErrors] = useState<ProviderError[]>([]);
  const requiredHeaders = ['npi', 'city', 'state', 'zip', 'addressLine1'];
  const optionalHeaders = ['firstName', 'lastName'];
  const validHeaders = [...requiredHeaders, ...optionalHeaders];

  const validateHeaders = (headers: string[] | undefined): boolean => {
    if (!headers) return false;
    const allHeadersAreValid = headers.every((header) => validHeaders.includes(header));
    if (!allHeadersAreValid) return false;

    const allRequiredArePresent = requiredHeaders.every((required) => headers.includes(required));
    return allRequiredArePresent;
  };

  const formatHeader = (header: string): string => camelCase(header).replaceAll(' ', '');

  const formatField = (field: string): string[] | string | null => {
    if (!field) return null;

    // Convert to array
    if (field.startsWith('[')) return trim(field, '[]').replaceAll(' ', '').split(',');
    return field;
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const [uploadedFile] = event.target.files!;

    if (uploadedFile.type !== 'text/csv') {
      setFormatParsingError(true);
      return;
    }

    if (uploadedFile) setCsvFile(uploadedFile);
  };

  const parseFile = () => {
    if (!csvFile) throw new Error('No CSV file in parsing function');
    Papa.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      transformHeader: formatHeader,
      transform: formatField,
      complete: parseResults,
      error: (error) => {
        store.showSnackbar(`Failed to parse csv file: ${error.message}`);
      },
    });
  };

  const parseResults = async (results: Papa.ParseResult<unknown>) => {
    if (results.errors.length > 0) {
      setFormatParsingError(true);
      return;
    }
    if (validateHeaders(results.meta.fields)) {
      if (results.data.length === 0) {
        return store.showSnackbar(messages.emptyFile);
      }

      const errors = validateData(results.data as Record<string, string | null>[]);
      if (errors.length) {
        setIsFileParsed(true);
        addErrors(errors);
        return store.showSnackbar('CSV parsing finished with errors');
      }

      setIsLoading(true);

      try {
        await createNewProviders(results.data);
        onFinish?.();
      } catch (error) {
        console.error(`Failed to create provider`, error);
        store.showSnackbar(messages.createError);
      }

      setIsLoading(false);
    } else {
      store.showSnackbar('CSV headers are invalid. Please see the documentation.');
    }

    setIsFileParsed(true);
  };

  const formatProviderInput = (provider: ProviderCSVRecord): BulkProviderInput => {
    const { addressLine1, city, state, zip, ...rest } = provider;

    const input = {
      ...rest,
      location: addressLine1
        ? {
            addressLine1,
            city,
            state,
            zip,
          }
        : undefined,
    };
    return input;
  };

  const createNewProviders = async (providers) => {
    if (!organization.id) {
      throw new Error('Tried to create new preferred providers with no organization id');
    }
    if (useBulkProviders) {
      const providersInput = providers.map(formatProviderInput);
      const input = { orgId: organization.id.toString(), providers: providersInput };
      const { data } = await bulkInsertProviders({ input });
      setSuccessCount(data.bulkUpsertPreferredProviders.newProviders);
      setSuccessLocationCount(data.bulkUpsertPreferredProviders.newLocations);
    } else {
      await Promise.allSettled(
        providers.map((newProvider: NewProviderInput, index: number) =>
          createNewProvider(newProvider, index),
        ),
      );
    }
  };

  const createNewProvider = async (newProvider: ProviderCSVRecord, providerIndex: number) => {
    if (!organization.id) {
      throw new Error('Tried to create new preferred providers with no organization id');
    }
    if (isValidProvider(newProvider, providerIndex)) {
      try {
        const input = formatProviderInput(newProvider);
        await createProvider({ input: { ...input, orgId: organization.id } });
        setSuccessCount((prevCount) => prevCount + 1);
      } catch (error: any) {
        console.error(`Failed to create provider`, error);
        addErrors([{ messages: [error.message], index: providerIndex, npi: newProvider.npi }]);
      }
    }
  };

  const isProviderNpiFieldValid = (provider: NewProviderInput): boolean => {
    if (isEmpty(provider.npi)) {
      return false;
    }
    // Allow 10 digits in NPI field
    return !(!/^\d+$/.test(provider.npi) || provider.npi.length !== 10);
  };

  const isProviderLocationFieldsValid = (provider: any): boolean => {
    if (!provider?.city && !provider?.state && !provider?.zip && !provider?.addressLine1)
      return true;
    return !(!provider?.city || !provider?.state || !provider?.zip || !provider?.addressLine1);
  };

  const isValidProvider = (provider: NewProviderInput, providerIndex: number): boolean => {
    const providerErrors = getProviderErrors(provider);

    if (providerErrors.length) {
      addErrors([{ index: providerIndex, messages: providerErrors, npi: provider.npi }]);
      return false;
    }
    return true;
  };

  const getProviderErrors = (provider: NewProviderInput): string[] => {
    const providerErrors: string[] = [];

    if (!isProviderNpiFieldValid(provider))
      providerErrors.push(`No valid format for NPI: ${provider.npi}`);
    if (!isProviderLocationFieldsValid(provider))
      providerErrors.push(
        `Some locations fields are empty. Either fill them all or leave them all empty.`,
      );
    return providerErrors;
  };

  const handleOnClose = () => {
    resetAllfields();
    onClose();
  };

  const resetAllfields = () => {
    setCsvFile(null);
    setIsFileParsed(false);
    setSuccessCount(0);
    setSuccessLocationCount(0);
    setErrors([]);
    setFormatParsingError(false);
  };

  const addErrors = (newError: ProviderError[]) => {
    setErrors((prevErrors) => [...prevErrors, ...newError]);
  };

  const getDialogTitle = useCallback(() => {
    let title = !formatParsingError ? 'Load providers from CSV' : 'Upload Error';
    if (isFileParsed) {
      title = 'Upload Summary';
    }

    return title;
  }, [isFileParsed, formatParsingError]);

  return (
    <Dialog onClose={handleOnClose} open={isOpen}>
      <Box display="flex" justifyContent="space-between" alignItems="center" paddingRight="1rem">
        <DialogTitle>{getDialogTitle()}</DialogTitle>
        <IconButton onClick={handleOnClose}>
          <tableIcons.Close />
        </IconButton>
      </Box>
      <DialogContent dividers>
        {!isFileParsed &&
          (!formatParsingError ? (
            <>
              {!csvFile ? (
                <Box style={{ textAlign: 'center' }}>
                  <label htmlFor="csv-file">
                    <input
                      type="file"
                      id="csv-file"
                      accept=".csv"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      component="span"
                      startIcon={<tableIcons.Backup />}
                    >
                      Select CSV File
                      {isLoading && <CircularProgress size={24} style={{ position: 'absolute' }} />}
                    </Button>
                  </label>
                </Box>
              ) : (
                <Button
                  onClick={parseFile}
                  color="primary"
                  variant="contained"
                  component="span"
                  startIcon={<tableIcons.Check />}
                  disabled={isLoading}
                >
                  Click to upload {csvFile.name}
                  {isLoading && <CircularProgress size={24} style={{ position: 'absolute' }} />}
                </Button>
              )}
            </>
          ) : (
            <DialogContentText>
              <span className="summary-title"> {INVALID_FORMAT_MESSAGE} </span>
            </DialogContentText>
          ))}

        {isFileParsed && successCount > 0 && (
          <DialogContentText>
            <span className="summary-title">Successfully created providers: </span>
            <span>{successCount}</span>
            {useBulkProviders && (
              <>
                <br />
                <span className="summary-title">Successfully created locations: </span>
                <span>{locationSuccessCount}</span>
              </>
            )}
          </DialogContentText>
        )}

        {isFileParsed && errors.length > 0 && (
          <>
            <DialogContentText>
              <span className="summary-title">{`Failed to ${
                useBulkProviders
                  ? `upload the CSV file due to the invalid format for the rows below`
                  : 'create'
              }: `}</span>
              <span>{errors.length}</span>
            </DialogContentText>
            <DialogContentText>
              <span className="summary-title">
                Errors in the CSV file
                {errors.length > 10
                  ? ' (See an example of the first 10 rows with an invalid format):'
                  : ':'}
              </span>
              <ul>
                {errors
                  .slice(0, 10)
                  .sort(sortByIndex)
                  .map((error) => (
                    <li key={error.index}>
                      The provider details on row no. {error.index + 1} are invalid
                      {error.npi ? ` (NPI: ${error.npi})` : ''}:
                      <ul>
                        {error.messages.map((x) => (
                          <li>{x}</li>
                        ))}
                      </ul>
                    </li>
                  ))}
              </ul>
            </DialogContentText>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
