import gql from 'graphql-tag';

export const getAllApplicationsQuery = gql`
  query getAllApplicationsQuery {
    getAllApplications {
      id
      displayName
      configUrl
      supportedDataSources {
        id
        accountId
        name
        dataSourceEnum
        namespace
      }
    }
  }
`;
