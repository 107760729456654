export const gracefullyTryAsync = async (
  fn: () => Promise<any>,
  onError: () => Promise<void> | void,
): Promise<any> => {
  try {
    const res = await fn();
    return res;
  } catch (error) {
    return onError();
  }
};
