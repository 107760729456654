import { createStyles } from '@material-ui/core';

export const styles = () =>
  createStyles({
    inspect: {
      display: 'flex',
      alignItems: 'center',
    },
    box: { display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' },
    flexGrow: { flexGrow: 1 },
    button: { padding: '1.5rem' },
  });
