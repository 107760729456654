import React, { useState } from 'react';
import { IconButton, TextField, withStyles, WithStyles, InputAdornment } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { styles } from './style';
import { EmailDialog } from './email-dialog';

interface EmailInputProps extends WithStyles<typeof styles> {
  linkedEhrUser?: string | null;
  userId?: string | null;
  organizationId?: number | null;
  email?: string | null;
  isOrgAdmin?: boolean | null;
  onSubmit: (newData) => void;
}

export const EmailInput = withStyles(styles)(
  ({
    linkedEhrUser,
    userId,
    organizationId,
    email,
    isOrgAdmin,
    onSubmit,
    classes,
  }: EmailInputProps) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <>
        <TextField
          fullWidth
          label="Email"
          value={email}
          type="email"
          className={`${classes.emailPreviewInput}`}
          InputProps={{
            disabled: true,
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Edit Email"
                  onClick={() => setIsOpen(true)}
                  onMouseDown={(event) => event.preventDefault()}
                >
                  <EditIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <EmailDialog
          linkedEhrUser={linkedEhrUser}
          userId={userId}
          organizationId={organizationId}
          email={email}
          isOrgAdmin={isOrgAdmin}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onSubmit={onSubmit}
        />
      </>
    );
  },
);
