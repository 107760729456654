import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

interface ActionDialogProps {
  isOpen: boolean;
  dialogTitle: string;
  handleSubmit: () => void;
  handleCancel: () => void;
  dialogContent?: JSX.Element;
}

export const ActionDialog = ({
  dialogTitle,
  handleSubmit,
  handleCancel,
  isOpen,
  dialogContent,
}: ActionDialogProps) => (
  <Dialog fullWidth open={isOpen}>
    <DialogTitle>{dialogTitle}</DialogTitle>

    {dialogContent && <DialogContent>{dialogContent}</DialogContent>}

    <DialogActions>
      <Button onClick={handleCancel} variant="outlined">
        Cancel
      </Button>
      <Button onClick={handleSubmit} variant="contained" color="secondary">
        OK
      </Button>
    </DialogActions>
  </Dialog>
);
