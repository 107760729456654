import {
  Box,
  Button,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Chip,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import PlusIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { isEmpty, startCase } from 'lodash-es';
import MaterialTable, { Column, Query, QueryResult } from 'material-table';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { getAllOrganizationsQuery } from '../../api/organization';
import { useApi } from '../../api/use-api';
import {
  createUserMutation,
  deleteUserMutation,
  resendActivationMailMutation,
  updateUserMutation,
  getUsersPaginationOmitIsOrgAdminQuery,
  createConsoleUserMutation,
  deleteConsoleUserMutation,
} from '../../api/users';
import { tableIcons } from '../../assets/material-table-icons';
import { useSafeState } from '../../common/use-safe-state';
import { ExtendedUser } from '../../common/user/types';
import { extractCommonInput, transformUser } from '../../common/user/user-logic';
import {
  GetAllOrganizationsResult,
  MutationCreateUserArgs,
  MutationDeleteUserArgs,
  MutationResendActivationMailArgs,
  MutationUpdateUserArgs,
  Organization,
  User,
  UserType,
  QueryGetUsersPaginationArgs,
  GetUsersPaginationResult,
  UserCreateType,
  MutationCreateConsoleUserArgs,
  MutationDeleteConsoleUserArgs,
  SuccessStatus,
  ExternalRole,
} from '../../generated/graphql';
import { store } from '../../stores/store';
import { AlertDialog, DeleteDialog } from './dialogs';
import { messages } from './messages';
import { UserDetailsDialog } from './user-details';
import './users.scss';
import { ErrorTypes } from './errors';
import { validateNPI } from '../../common/validation/validateNpi';

export const Users = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const organizationIdSearchParam = params.get('organization_id') || '';

  const [searchQuery, setSearchQuery] = useState<string>(organizationIdSearchParam);
  const [searchBy, setSearchBy] = useState<string>(
    organizationIdSearchParam ? 'organization_id' : 'email',
  );

  const [isNewSearch, setIsNewSearch] = useState<boolean>(false);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogUser, setDialogUser] = useState<ExtendedUser>();

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertUser, setAlertCurrentUser] = useState<User>();

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(false);

  const [organizations, setOrganizations] = useSafeState<Organization[]>([]);

  const [getUsersPaginationOmitIsOrgAdmin] = useApi<
    QueryGetUsersPaginationArgs,
    GetUsersPaginationResult
  >(getUsersPaginationOmitIsOrgAdminQuery);
  const [createUserApi] = useApi<MutationCreateUserArgs, User>(createUserMutation);
  const [createConsoleUserApi] = useApi<MutationCreateConsoleUserArgs, SuccessStatus>(
    createConsoleUserMutation,
  );
  const [deleteConsoleUserApi] = useApi<MutationDeleteConsoleUserArgs, SuccessStatus>(
    deleteConsoleUserMutation,
  );
  const [updateUserApi] = useApi<MutationUpdateUserArgs, User>(updateUserMutation);
  const [deleteUser] = useApi<MutationDeleteUserArgs, string>(deleteUserMutation);
  const [resendActivationMail] = useApi<MutationResendActivationMailArgs, string>(
    resendActivationMailMutation,
  );
  const [getOrganizations] = useApi<void, GetAllOrganizationsResult>(getAllOrganizationsQuery);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState<ExtendedUser>({});

  const materialTableRef = useRef(null);

  useEffect(() => {
    const run = async () => {
      const orgs = await getOrganizations();
      const organizationsData = orgs?.data?.getAllOrganizations?.results ?? [];
      setOrganizations(organizationsData as any);
    };

    run();
  }, []);

  useEffect(() => {
    if (!dialogOpen) setDialogUser(undefined);
  }, [dialogOpen]);

  const onAlertAgree = async () => {
    try {
      setAlertOpen(false);

      if (!alertUser || !alertUser.id || !alertUser.email) {
        return store.showSnackbar(messages.sendActivationMailError);
      }

      await resendActivationMail({
        input: {
          id: alertUser.id,
          email: alertUser.email,
          userType: UserType.VimEhrUser,
        },
      });

      store.showSnackbar(messages.sendActivationMailSuccess);
    } catch (error) {
      store.showSnackbar(messages.sendActivationMailError);
    }
  };

  const onUserAdded = () => store.showSnackbar(messages.createSuccess);

  const createUser = (newData) => {
    const extractedUser = extractCommonInput(newData);
    if (extractedUser.isOrgAdmin) {
      return createConsoleUserApi({
        input: {
          accountId: newData.organization.accountId,
          permittedOrganizations: { all: true },
          email: newData.email,
          externalRoles: [ExternalRole.ACCOUNT_ADMIN],
          vcUserMetadata: {
            ...extractedUser,
            organizationId: newData.organization.id,
            linkedEhrUser: newData.linkedEhrUser,
            metadata: { createType: UserCreateType.CONSOLE },
          },
        },
      });
    }
    return createUserApi({
      input: {
        metadata: { createType: UserCreateType.CONSOLE },
        organizationId: newData.organization?.id,
        email: newData.email,
        linkedEhrUser: newData.linkedEhrUser,
        ...extractedUser,
      },
    }).then(() => {
      onUserAdded();
    });
  };

  const updateUser = async (newData, oldData) => {
    const extractedUser = extractCommonInput(newData);

    if (oldData.isOrgAdmin !== newData.isOrgAdmin) {
      if (extractedUser.isOrgAdmin) {
        await createConsoleUserApi({
          input: {
            accountId: newData.organization.accountId,
            permittedOrganizations: { all: true },
            email: newData.email,
            externalRoles: [ExternalRole.ACCOUNT_ADMIN],
          },
        });
      } else if (extractedUser.isOrgAdmin === false) {
        await deleteConsoleUserApi({
          input: { accountId: newData.organization.accountId, externalId: newData.id.toString() },
        });
      }
    }

    // eslint-disable-next-line @typescript-eslint/return-await
    return updateUserApi({
      input: { externalId: newData.id as string, ...extractedUser },
    }).then((result) => {
      if (!oldData || !result) return store.showSnackbar(messages.updateError);
      store.showSnackbar(messages.updateSuccess);
    });
  };

  const onUserSubmit = async (newData, oldData, isNewUser) => {
    try {
      setIsSubmitButtonDisabled(true);

      if (newData.isOrgAdmin && !newData.email) {
        return store.showSnackbar(ErrorTypes.VimOrgAdminValidationError);
      }

      const npiError = validateNPI(newData.npi);

      if (npiError) {
        store.showSnackbar(npiError);
        return;
      }

      isNewUser ? await createUser(newData) : await updateUser(newData, oldData);

      setDialogOpen(false);
    } catch (error: any) {
      const failedAction = isNewUser ? messages.createError : messages.updateError;
      const errorMessage = error?.message;
      const errorName = error?.name || error?.extensions?.error?.name;
      const errorDisplayedMessage =
        (errorName === 'GraphQLError' || !errorName) && errorMessage ? errorMessage : failedAction;
      store.showSnackbar(errorDisplayedMessage);
    } finally {
      onQueryChange();
      setIsSubmitButtonDisabled(false);
    }
  };

  const formatDate = (date: string) => {
    return moment(date).format('MM/DD/YYYY');
  };
  const getColumns = () => {
    const columns: any = [];

    columns.push(
      { title: 'Email', field: 'email' },
      {
        title: 'Created',
        field: 'createdAt',
        render: (user: ExtendedUser) => <div>{formatDate(user.createdAt as string)}</div>,
      },
      {
        title: 'Activated',
        field: 'activatedAt',
        type: 'boolean',
        render: (user: ExtendedUser) => {
          if (!user.activatedAt) return;
          return (
            <Chip
              style={{ fontSize: '1.25rem', height: '2rem', color: '#fff', background: '#0AB39C' }}
              label={formatDate(user.activatedAt)}
            />
          );
        },
      },
      {
        title: 'Tag',
        field: 'tag',
      },
      {
        title: 'First Name',
        field: 'firstName',
      },
      {
        title: 'Last Name',
        field: 'lastName',
      },
      {
        title: 'Organization',
        field: 'organization',
        render: (user: ExtendedUser) => <div>{user?.organization?.name || ''}</div>,
        sorting: false,
      },
      {
        title: 'Products',
        field: 'products',
        sorting: false,
        render: (user: ExtendedUser) => {
          return <div>{(user?.products ?? []).map(startCase).join(', ')}</div>;
        },
        validate: ({ organization, products: userProducts }) => {
          if (!organization?.products) return true;
          return userProducts?.every((product) =>
            organization.products.map(({ name }) => name).includes(product),
          );
        },
      },
      {
        title: 'NPI',
        field: 'npi',
      },
      {
        title: 'TIN',
        field: 'tin',
        render: (user: ExtendedUser) => (
          <div>
            {(user?.ehrUser?.tin ?? []).map((tin) => startCase(tin || undefined)).join(', ')}
          </div>
        ),
      },
      {
        title: 'Phone Number',
        field: 'phoneNumber',
      },
      {
        title: 'Address',
        field: 'address.fullAddress',
        sorting: false,
      },
      {
        title: 'Linked EHR user',
        field: 'linkedEhrUser',
      },
    );

    return columns.filter((column) => !!column) as Column<any>[];
  };

  const handleUserDelete = async (user: ExtendedUser) => {
    try {
      const userId = user.id!;
      if (user.organization) {
        await deleteUser({ input: { id: userId!, isAdmin: false } });
      }
      if (user.accountId) {
        await deleteConsoleUserApi({
          input: { accountId: user.accountId, externalId: userId },
        });
      }
      store.showSnackbar(messages.deleteSuccess);
    } catch {
      store.showSnackbar(messages.deleteError);
    }
    onQueryChange();
  };

  const getActions = () => {
    const resend = (rowData: ExtendedUser) => ({
      icon: () => <SendIcon />,
      disabled: !!rowData.activatedAt,
      tooltip: !rowData.activatedAt ? 'Resend Activation Mail' : undefined,
      onClick: () => {
        if (!rowData?.id) return store.showSnackbar(messages.sendActivationMailError);
        setAlertCurrentUser(rowData);
        setAlertOpen(true);
      },
    });

    const edit = (rowData: ExtendedUser) => ({
      icon: () => <EditIcon />,
      tooltip: 'Edit',
      onClick: () => {
        if (!rowData.id) return store.showSnackbar(messages.rowMissingId);
        setDialogUser(rowData);
        setDialogOpen(true);
      },
    });

    const deleteUserAction = (rowData: ExtendedUser) => ({
      icon: () => <DeleteOutline />,
      tooltip: 'Delete',
      onClick: () => {
        if (!rowData.id) return store.showSnackbar(messages.rowMissingId);
        setUserToDelete(rowData);
        setOpenDeleteDialog(true);
      },
    });

    return [resend, edit, deleteUserAction];
  };

  const getTableData = async (query: Query<ExtendedUser>): Promise<QueryResult<ExtendedUser>> => {
    try {
      const { error, page, pageSize: perPage, orderDirection, orderBy } = query;

      if (error) store.showSnackbar(error.message);

      const { data } = await getUsersPaginationOmitIsOrgAdmin({
        input: {
          page: isNewSearch ? 0 : page,
          perPage,
          q: searchQuery?.trim(),
          filter: searchBy,
          orderDirection: orderDirection || 'asc',
          orderBy: orderBy?.field || searchBy,
        },
      });

      const { count, users } = data?.getUsersPagination;
      const transformedUsers = users.map(transformUser);

      setIsNewSearch(false);

      return {
        data: transformedUsers,
        totalCount: isEmpty(transformedUsers) ? 0 : count || 0,
        page: isNewSearch ? 0 : page,
      };
    } catch (error) {
      store.showSnackbar(messages.searchError);
      return { data: [], page: 0, totalCount: 0 };
    }
  };

  const onQueryChange = () => (materialTableRef.current as any).onQueryChange();

  return (
    <>
      <Box style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
        <TextField
          label="Search for users"
          variant="outlined"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setIsNewSearch(true);
              onQueryChange();
            }
          }}
          value={searchQuery}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
          style={{ flexGrow: 1 }}
        />
        <FormControl variant="outlined" style={{ flexGrow: 1 }}>
          <InputLabel>Search by</InputLabel>
          <Select
            label="Search by"
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value as string)}
          >
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="linked_ehr_user">Linked EHR User</MenuItem>
            <MenuItem value="first_name">First Name</MenuItem>
            <MenuItem value="last_name">Last Name</MenuItem>
            <MenuItem value="organization">Organization</MenuItem>
            <MenuItem value="organization_id">Organization ID</MenuItem>
            <MenuItem value="phone_number">Phone Number</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          startIcon={<CloseIcon />}
          style={{ padding: '1.5rem' }}
          onClick={() => {
            setSearchBy('email');
            setSearchQuery('');
            setIsNewSearch(true);

            // clear query string from URL
            window.history.replaceState(null, '', window.location.pathname);

            onQueryChange();
          }}
        >
          Reset
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<SearchIcon />}
          style={{ padding: '1.5rem' }}
          onClick={() => {
            setIsNewSearch(true);
            onQueryChange();
          }}
        >
          Search
        </Button>
        <Button
          variant="contained"
          startIcon={<PlusIcon />}
          style={{ padding: '1.5rem', backgroundColor: '#083a6b', color: 'white' }}
          onClick={() => setDialogOpen(true)}
        >
          Create User
        </Button>
      </Box>
      <MaterialTable
        icons={tableIcons}
        columns={getColumns()}
        data={getTableData}
        tableRef={materialTableRef}
        options={{
          pageSize: 10,
          sorting: true,
          pageSizeOptions: [],
          search: false,
          showTitle: false,
          draggable: false,
          toolbar: false,
          headerStyle: { backgroundColor: '#18202c', color: '#FFF' },
        }}
        actions={getActions()}
      />
      <UserDetailsDialog
        userId={dialogUser?.id}
        open={dialogOpen}
        organizations={organizations}
        onClose={() => setDialogOpen(false)}
        onSubmit={onUserSubmit}
        isSubmitButtonDisabled={isSubmitButtonDisabled}
      />
      <AlertDialog open={alertOpen} onAgree={onAlertAgree} onClose={() => setAlertOpen(false)} />
      <DeleteDialog
        open={openDeleteDialog}
        user={userToDelete}
        onDelete={handleUserDelete}
        onClose={() => {
          setOpenDeleteDialog(false);
          setUserToDelete({});
        }}
      />
    </>
  );
};
