import axios from 'axios';

export const env = (window as any).$vim_environment?.APP_ENV;

export const isNotLocal = env !== 'local';

export const httpClient = axios.create({
  baseURL: `${isNotLocal ? '/organization-admin' : ''}/console-api/api`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
