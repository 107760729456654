import {
  Box,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import MaterialTable, { MTableToolbar } from 'material-table';
import React, { useState, useEffect } from 'react';
import { useApi } from '../../api/use-api';
import { auth0UsersQuery, deleteAuth0UsersMutation } from '../../api/users';
import { tableIcons } from '../../assets/material-table-icons';
import {
  QueryAuth0InputArgs,
  Auth0UsersResult,
  Auth0UsersQueryType,
  ReducedAuth0User,
  MutationDeleteAuth0UsersArgs,
  DeleteAuth0UsersResult,
} from '../../generated/graphql';
import { store } from '../../stores/store';
import { DeleteDialog } from './dialogs';
import { getTheme } from '../main/style';
import { featureFlagsClient } from '../../services';

const MAX_SELECTIONS = 100;

export const Investigator = () => {
  const [searchBy, setSearchBy] = useState<string>();

  const [usersData, setUsersData] = useState<ReducedAuth0User[]>([]);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [shouldUseCustomInvestigatorThemeFF, setShouldUseCustomInvestigatorThemeFF] = useState<
    boolean
  >(false);

  const [auth0UsersApi] = useApi<QueryAuth0InputArgs, Auth0UsersResult>(auth0UsersQuery);
  const [deleteAuth0UsersApi] = useApi<MutationDeleteAuth0UsersArgs, DeleteAuth0UsersResult>(
    deleteAuth0UsersMutation,
  );

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [usersToDelete, setUsersToDelete] = useState<ReducedAuth0User[]>([]);

  const tableColumns = [
    { title: 'Name', field: 'name' },
    {
      title: 'Connection',
      field: 'connection',
    },
    {
      title: 'Logins Count',
      field: 'logins_count',
    },
  ];

  const handleUsersDelete = async (userIds: string[]) => {
    try {
      setIsDataLoading(true);
      const { data } = await deleteAuth0UsersApi({ input: { userIds } });
      const deletedUserIds = data?.deleteAuth0Users.userIds || [];

      setUsersData(usersData?.filter((user) => !deletedUserIds.includes(user.user_id)));
      setIsDataLoading(false);

      const message =
        deletedUserIds.length === userIds.length
          ? `Deleted ${deletedUserIds.length} users successfully`
          : `Deleted ${deletedUserIds.length} users successfully, ${
              userIds.length - deletedUserIds.length
            } failed`;
      store.showSnackbar(message);
    } catch {
      store.showSnackbar('Failed to delete users, please try again');
    }
  };

  const tableActions = [
    {
      icon: () => <DeleteOutline />,
      tooltip: 'Delete User',
      onClick: (_, data) => {
        setUsersToDelete(data);
        setOpenDeleteDialog(true);
      },
    },
  ];

  const loadZombiesData = async () => {
    setIsDataLoading(true);
    const { data } = await auth0UsersApi({ input: { type: Auth0UsersQueryType.Zombies } });

    setUsersData(data?.auth0Users.users || []);
    setIsDataLoading(false);
  };

  useEffect(() => {
    if (searchBy === Auth0UsersQueryType.Zombies) {
      loadZombiesData();
    } else {
      setUsersData([]);
    }
  }, [searchBy]);

  useEffect(() => {
    const loadFFs = async () => {
      const shouldUseCustomInvestigatorTheme = await featureFlagsClient.getFlag({
        flagName: 'console.should_use_custom_investigator_theme',
        defaultValue: false,
      });
      setShouldUseCustomInvestigatorThemeFF(shouldUseCustomInvestigatorTheme);
    };
    loadFFs();
  }, []);

  const theme = shouldUseCustomInvestigatorThemeFF
    ? createMuiTheme({ ...getTheme(), palette: { secondary: { main: '#009be5' } } })
    : getTheme();

  return (
    <>
      <Box style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
        <FormControl variant="outlined" style={{ flexGrow: 1, marginRight: 0, marginLeft: 'auto' }}>
          <InputLabel>Auth0 users query type</InputLabel>
          <Select
            label="Auth0 users query type"
            value={searchBy}
            onChange={(e) => {
              setSearchBy(e.target.value as string);
            }}
          >
            <MenuItem value={Auth0UsersQueryType.Zombies}>{Auth0UsersQueryType.Zombies}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          icons={tableIcons}
          columns={tableColumns}
          data={usersData}
          isLoading={isDataLoading}
          title=""
          options={{
            headerStyle: { backgroundColor: '#18202c', color: '#FFF' },
            selection: true,
          }}
          components={{
            Toolbar: (props) => {
              const { selectedRows } = props;
              if (
                selectedRows?.length > MAX_SELECTIONS &&
                usersToDelete.length === 0 &&
                !isDataLoading
              ) {
                store.showSnackbar(`You can only select up to ${MAX_SELECTIONS} users`);
              }
              return (
                <MTableToolbar {...props} selectedRows={selectedRows?.slice(0, MAX_SELECTIONS)} />
              );
            },
          }}
          actions={tableActions}
        />
      </MuiThemeProvider>
      <DeleteDialog
        open={openDeleteDialog}
        users={usersToDelete}
        onDelete={handleUsersDelete}
        onClose={() => {
          setOpenDeleteDialog(false);
          setUsersToDelete([]);
        }}
      />
    </>
  );
};
