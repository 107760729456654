import { Auth0ProviderOptions } from '@auth0/auth0-react';

interface Auth0RemoteConfig {
  domain: string;
  clientId: string;
}

export const auth0Config: Auth0ProviderOptions = {
  audience: 'http://console-api',
  redirectUri: window.location.origin,
  domain: '',
  clientId: '',
};

export const updateAuth0Config = (config?: Auth0RemoteConfig) => {
  if (!config) return;

  const { domain, clientId } = config;

  auth0Config.clientId = clientId;
  auth0Config.domain = domain;
};
