import gql from 'graphql-tag';

export const adminFragment = gql`
  fragment AdminFragment on Admin {
    id
    email
  }
`;

export const getAdminsQuery = gql`
  query getAdmins {
    getAdmins {
      ...AdminFragment
    }
  }
  ${adminFragment}
`;

export const createAdminMutation = gql`
  mutation createAdmin($input: CreateAdminInput!) {
    createAdmin(input: $input) {
      ...AdminFragment
    }
  }
  ${adminFragment}
`;
