export const env = (window as any).$vim_environment?.APP_ENV;

/** The New Console's origin. Both old & new Consoles have same origin in all environments, except of local environment (because of different ports)  */
export function getOrganizationConsoleOrigin() {
  return env === 'local' ? `http://localhost:3014` : window.location.origin;
}

export function getFullOrganizationConsoleUrl(shouldUseUniversalLogin?: boolean) {
  return (
    getOrganizationConsoleOrigin() +
    `/organization-admin/login${shouldUseUniversalLogin ? '/callback?alreadyLoggedIn=true' : ''}`
  );
}

const URLS_TO_ENVS = {
  'console.dev.devim.io': 'dev',
  'console.staging.getvim.com': 'staging',
  'console.staging.devim.io': 'staging',
  'console.demo.getvim.com': 'demo',
  'console.getvim.com': 'prod',
  'localhost:5000': 'local',
};
export function getEnvByUrlHost(): string {
  return URLS_TO_ENVS[window.location.host];
}

export function getConsoleUrlByEnv(env: string): string {
  for (const [key, value] of Object.entries(URLS_TO_ENVS)) {
    if (env === value) {
      return key;
    }
  }
  return '';
}
