import gql from 'graphql-tag';

export const getOrganizationAdminsQuery = gql`
  query getOrganizationAdmins {
    getOrganizationAdmins {
      id
      email
      organizationId
      organizationName
    }
  }
`;
