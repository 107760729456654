import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useState } from 'react';
import { SelectedUsers } from '../types';

type FormProps = {
  name: string;
  title: string;
  children: React.ReactNode;
  disabled: boolean;
  onChange: (e: { selectedUsers: SelectedUsers }) => void;
};

export const UpdateOrgUsersForm: React.FC<FormProps> = ({
  name,
  title,
  children,
  disabled,
  onChange,
}: FormProps) => {
  const [selectedUsers, setSelectedUsers] = useState<SelectedUsers>(SelectedUsers.None);
  const createChangeHandler = (selectionValue: SelectedUsers) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newSelectedUsers = event.target.checked ? selectionValue : SelectedUsers.None;
    setSelectedUsers(newSelectedUsers);
    onChange({ selectedUsers: newSelectedUsers });
  };

  return (
    <Box>
      <h4>{title}</h4>
      <TableContainer>
        <Table className="bulkEditModal-Table">
          <TableHead>
            <TableRow>
              <TableCell className="column1">Organization {title}</TableCell>
              <TableCell className="column2">Add to all users</TableCell>
              <TableCell className="column3">Add to users without {name}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{children}</TableCell>
              <TableCell>
                <Checkbox
                  disabled={disabled}
                  checked={selectedUsers === SelectedUsers.AllUsers}
                  onChange={createChangeHandler(SelectedUsers.AllUsers)}
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  disabled={disabled}
                  checked={selectedUsers === SelectedUsers.UsersWithoutValue}
                  onChange={createChangeHandler(SelectedUsers.UsersWithoutValue)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
