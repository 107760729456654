import gql from 'graphql-tag';

export const getAuditsQuery = gql`
  query getAudits($input: GetAuditsInput!) {
    getAudits(input: $input) {
      createdAt
      appName
      actionName
      actingUser {
        id {
          technicalId
          readableId
        }
        organization
      }
      affectedEntity {
        id {
          technicalId
          readableId
        }
        organization
        type
        affectedFields {
          name
          previousValue
          newValue
        }
      }
      sourceIp
      actionResult
      totalCount
    }
  }
`;
