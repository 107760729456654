import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CancelIcon from '@material-ui/icons/Cancel';
import { ehrSystemsConfig } from '../organization-details/utils';
import { useApi } from '../../api/use-api';
import { MutationSeamlessVerificationArgs, SuccessStatus } from '../../generated/graphql';
import { seamlessVerificationMutation } from '../../api/users';

export const SeamlessVerification = () => {
  const [success, setSuccess] = useState<boolean | undefined>();
  const [username, setUsername] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [ehrUrl, setEhrUrl] = useState('');
  const [ehrSystem, setEhrSystem] = useState('ecw');

  const [seamlessVerificationApi, isLoading] = useApi<
    MutationSeamlessVerificationArgs,
    SuccessStatus
  >(seamlessVerificationMutation);

  const handleVerify = async () => {
    setSuccess(undefined);

    const {
      data: { seamlessVerification },
    } = await seamlessVerificationApi({
      input: { username, authToken, ehrUrl, ehrSystem },
    });

    const { success } = seamlessVerification;

    setSuccess(success);
  };

  return (
    <Container maxWidth="xs">
      <Box display="grid" style={{ gap: '2rem' }}>
        <TextField
          label="username"
          variant="outlined"
          size="small"
          value={username}
          onChange={({ target }) => setUsername(target?.value)}
        />
        <TextField
          label="auth token"
          variant="outlined"
          size="small"
          value={authToken}
          onChange={({ target }) => setAuthToken(target?.value)}
        />
        <TextField
          label="URL"
          variant="outlined"
          size="small"
          value={ehrUrl}
          onChange={({ target }) => setEhrUrl(target?.value)}
        />

        <FormControl variant="outlined" size="small">
          <InputLabel>EHR System</InputLabel>
          <Select
            value={ehrSystem}
            onChange={({ target }) => setEhrSystem(target?.value as string)}
            label="EHR System"
          >
            {Object.keys(ehrSystemsConfig).map((ehrSystem) => (
              <MenuItem value={ehrSystem} key={ehrSystem}>
                {ehrSystem}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          color="primary"
          variant="contained"
          disabled={isLoading}
          onClick={handleVerify}
          endIcon={<VerifiedUserIcon />}
        >
          {isLoading ? 'Verifying...' : 'Verify'}
        </Button>

        {success !== undefined && (
          <Box
            fontWeight="bold"
            color={success ? 'green' : 'red'}
            display="flex"
            alignItems="center"
          >
            <span>{success ? 'Success!' : 'Failed!'}</span>
            {success ? <VerifiedUserIcon /> : <CancelIcon />}
          </Box>
        )}
      </Box>
    </Container>
  );
};
