import React from 'react';
import { MenuItem, Select } from '@material-ui/core';

type SelectionKey = string | number;
type ResultKey = SelectionKey | undefined;
type SelectionValue = any;

export type Selection = { key: ResultKey | undefined; value: SelectionValue | undefined };
export type SelectionHandler = ({ selection }: { selection: Selection }) => void;
type SelectorProps = {
  selections: Record<SelectionKey, SelectionValue>;
  onSelect: SelectionHandler;
  label: string;
  allowNone: boolean;
  selectedKey: ResultKey;
};

function validateKey(key: unknown): key is ResultKey {
  return ['string', 'number', 'undefined'].includes(typeof key);
}

const NoneElement = () => <MenuItem value={undefined}>None</MenuItem>;

export default function Selector({
  selections,
  onSelect,
  label,
  allowNone = true,
  selectedKey,
}: SelectorProps) {
  return (
    <Select
      onChange={(e) => {
        if (!validateKey(e.target.value)) return;
        const targetKey = e.target.value;
        onSelect({
          selection: { key: targetKey, value: targetKey && selections[targetKey] },
        });
      }}
      label={label}
      value={selectedKey || ''}
    >
      {allowNone ? <NoneElement /> : undefined}
      {Object.entries(selections).map(([key, value]) => (
        <MenuItem value={key} key={key}>
          {value}
        </MenuItem>
      ))}
    </Select>
  );
}
