import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

interface AlertDialogProps {
  open: boolean;
  onClose: () => void;
  onAgree: () => void;
  onDisagree?: () => void;
}

export const AlertDialog = ({
  open,
  onClose,
  onAgree,
  onDisagree = () => {},
}: AlertDialogProps) => {
  const handleAgree = () => {
    onAgree();
    onClose();
  };

  const handleDisagree = () => {
    onDisagree();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Send an activation email to user?</DialogTitle>
      <DialogActions>
        <Button onClick={handleDisagree} variant="outlined">
          Disagree
        </Button>
        <Button onClick={handleAgree} color="primary" variant="contained" autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.defaultProps = {
  onDisagree: () => {},
};
