import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { QUERY_PARAMS } from './common/queryParams';
import { useCallback, useEffect, useState } from 'react';
import { getOrganizationConsoleOrigin } from './common/env';
// wrap useAuth0 hook and use the token from the query string if it exists
export const useAuth0Wrapper = () => {
  const { getAccessTokenSilently, loginWithRedirect, isAuthenticated, isLoading, ...rest } =
    useAuth0();

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const [token, setToken] = useState(urlSearchParams.get(QUERY_PARAMS.AUTHORIZATION_TOKEN));

  // listen to refresh token message
  useEffect(() => {
    const listener = (message) => {
      if (message.origin !== getOrganizationConsoleOrigin()) {
        return;
      }
      switch (message.data.event) {
        case 'update-access-token':
          if (message.data.token === token) return;
          setToken(message.data.token);
          break;
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [token, setToken]);

  const getAccessTokenSilentlyWrapper = useCallback(
    async (options?: any) => {
      if (token) return token;
      return await getAccessTokenSilently(options);
    },
    [token, getAccessTokenSilently],
  );

  return {
    ...rest,
    token,
    isAuthenticated: isAuthenticated || !!token,
    isLoading: isLoading && !token,
    getAccessTokenSilently: getAccessTokenSilentlyWrapper,
    loginWithRedirect,
  };
};
